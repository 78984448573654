import { Box, Grid, RadioGroup } from '@mui/material'
import {
  FormikErrors,
  useFormik,
  validateYupSchema,
  yupToFormErrors,
} from 'formik'
import { getFixedT } from 'i18next'
import { ReactElement } from 'react'
import { UniversalI18nNamespace } from 'universal-i18n-namespace'
import * as yup from 'yup'

import CardRadio from '~components/CardRadio'
import Layout from '~components/Layout'
import PrimaryButton from '~components/PrimaryButton'
import CompleteQuiz from '~images/complete-quiz.png'
import TalkImage from '~images/talk.png'
import { PageView } from '~services/analyticsService/analytics.service'
import { OnboardingType } from '~services/signupMachine/types/user'
import { handleFormBlur, handleFormSubmit } from '~utils/helpers'
import { SendType } from '~utils/machineTypes'

import './CallChoiceDecision.scss'

interface CallChoiceDecisionProps {
  send: SendType
}

interface FormValues {
  onboardingType: OnboardingType | undefined
}

const PAGE_NAME: PageView['pageName'] = 'callChoiceDecision'

export default function CallChoiceDecision({
  send,
}: CallChoiceDecisionProps): ReactElement {
  const tPage = getFixedT(
    null,
    UniversalI18nNamespace.Common,
    'callChoiceDecision'
  )

  let formErrors: FormikErrors<{ [x: string]: string }> = {}
  const validationSchema = yup.object({
    onboardingType: yup
      .mixed<OnboardingType>()
      .oneOf([
        OnboardingType.DigitalOnboarding,
        OnboardingType.VirtualAppointmentOnboarding,
      ])
      .required(),
  })

  const formik = useFormik({
    initialValues: {
      onboardingType: undefined,
    },
    initialErrors: { onboardingType: '' }, // needed for disabling btn initially
    validateOnChange: true,
    validateOnBlur: true,
    validate: (values: FormValues) => {
      try {
        formErrors = {}
        validateYupSchema<FormValues>(values, validationSchema, true)
      } catch (err) {
        formErrors = yupToFormErrors(err)
        return formErrors
      }
      return {}
    },
    onSubmit: (formValues: FormValues) => {
      send({
        type: 'UPDATE_LIGHTER_MEMBER_DATA',
        value: {
          onboardingType: formValues.onboardingType,
        },
      })
    },
  })

  return (
    <Layout className="call-choice-decision">
      <form
        onBlur={(e) =>
          handleFormBlur(e.target.name, PAGE_NAME, formErrors[e.target.name])
        }
        onSubmit={(e) => handleFormSubmit(e, PAGE_NAME, formik)}
      >
        <h1 className="pel-typography-h5-bold">{tPage('pageHeader')}</h1>
        <h2 className="call-choice-decision__sub-header">
          {tPage('pageSubHeader')}
        </h2>

        <RadioGroup
          name="onboardingType"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        >
          <Grid container spacing={4} justifyContent="center">
            <Grid item>
              <CardRadio
                imageSrc={TalkImage}
                header={tPage('bookNow')}
                description={tPage('bookNowDescription')}
                tag={tPage('recommended')}
                value={OnboardingType.VirtualAppointmentOnboarding}
              />
            </Grid>
            <Grid item>
              <CardRadio
                imageSrc={CompleteQuiz}
                header={tPage('bookLater')}
                description={tPage('bookLaterDescription')}
                value={OnboardingType.DigitalOnboarding}
              />
            </Grid>
          </Grid>
        </RadioGroup>

        <Box className="call-choice-decision__submit-button">
          <PrimaryButton
            type="submit"
            minwidth="100%"
            className={formik.isValid ? '' : 'Mui-disabled'}
            aria-disabled={!formik.isValid}
            data-testid="submit-button"
          >
            {tPage('submitButton')}
          </PrimaryButton>
        </Box>
      </form>
    </Layout>
  )
}
