export enum SignupMachineError {
  InternalError = 'internalError',
  NoAddictionSelected = 'noAddictionSelected',
  NoSession = 'noSession',
  NoUser = 'noUser',
  ResetPasswordCodeMismatch = 'resetPasswordCodeMismatch',
  ResetPasswordExpiredCode = 'resetPasswordExpiredCode',
  ResetPasswordInvalidPassword = 'resetPasswordInvalidPassword',
  ResetPasswordSthWentWrong = 'resetPasswordSthWentWrong',
  ProgressGetFail = 'progressGetFail',
  ProgressGetNoProgress = 'progressGetNoProgress',
  ProgressGetFailedParse = 'progressGetFailedParse',
  ProgressGetB2BAlreadySaved = 'progressGetB2BAlreadySaved',
  ProgressGetB2BFail = 'progressGetB2BFail',
  ProgressContextSchemaValidationError = 'progressContextSchemaValidationError',
}
