import { TreatmentProgram, WorkingQuittingPriority } from 'global.constants'

export function getUpdatedSelectedAddictionAndPriorities<T>(
  quittingPriority: WorkingQuittingPriority,
  criteria: (quitting: TreatmentProgram) => boolean,
  props: T
) {
  const sortedPriorityEntries: [string, WorkingQuittingPriority[number]][] =
    Object.entries(quittingPriority || {}).sort(
      ([a], [b]) => parseInt(a, 10) - parseInt(b, 10)
    )

  const newItemOrder: WorkingQuittingPriority[number][] = []
  const availablePrograms: TreatmentProgram[] = []
  const unavailablePrograms: TreatmentProgram[] = []

  // Add available
  sortedPriorityEntries.forEach(([, item]) => {
    if (criteria(item.quitting)) {
      newItemOrder.push(item)
      availablePrograms.push(item.quitting)
    }
  })

  // Add unavailable
  sortedPriorityEntries.forEach(([, item]) => {
    if (!criteria(item.quitting)) {
      newItemOrder.push({ ...item, ...props })
      unavailablePrograms.push(item.quitting)
    }
  })

  const newQuittingPriority: WorkingQuittingPriority = Object.fromEntries(
    newItemOrder.map((item, index) => {
      return [index + 1, item]
    })
  )

  const newSelectedAddiction = newItemOrder[0].quitting

  return {
    newSelectedAddiction,
    newQuittingPriority,
    availablePrograms,
    unavailablePrograms,
  }
}
