import {
  Box,
  FormControlLabel,
  FormControlLabelProps,
  SvgIcon,
  styled,
} from '@mui/material'
import React, { ReactElement } from 'react'

interface SelectLayoutProps {
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  id: string
  title: string
  subtitle?: string
  testId?: string
  checked: boolean
  children: React.ReactElement
  isSubLayout: boolean
  error?: boolean
}

/**
 * SelectLayout
 *
 * A Form Control Label with customised styles for radio or checkboxes.
 *
 */

export default function SelectLayout(props: SelectLayoutProps): ReactElement {
  const {
    icon,
    id,
    title,
    subtitle,
    testId,
    checked,
    isSubLayout,
    error = false,
  } = props

  return (
    <StyledFormControlLabel
      checked={checked}
      control={props.children}
      error={error}
      subtitle={subtitle}
      label={
        <>
          <Box
            display="flex"
            alignItems="center"
            marginTop={subtitle ? '4px' : 0}
          >
            {!!icon && (
              <SvgIcon
                component={icon}
                inheritViewBox
                sx={{
                  m: '0 12px 0 8px',
                  fontSize: '18px',
                }}
                className="pel-colour-global-primary"
                data-testid="svg-icon"
              />
            )}
            <Box display="flex">
              <p
                className={
                  isSubLayout ? 'pel-typography-b3' : 'pel-typography-b2-bold'
                }
                style={{ margin: '0' }}
                id={id}
              >
                {title}
              </p>
            </Box>
          </Box>
          {!!subtitle && (
            <Box display="flex">
              <p
                className="pel-typography-b4"
                style={{ margin: '0 0 4px 38px', lineHeight: '16px' }}
              >
                {subtitle}
              </p>
            </Box>
          )}
        </>
      }
      tabIndex={-1}
      data-testid={testId}
    />
  )
}

const StyledFormControlLabel = styled(
  ({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    error,
    ...props
  }: FormControlLabelProps & Pick<SelectLayoutProps, 'error' | 'subtitle'>) => (
    <FormControlLabel {...props} />
  )
)(({ theme, checked, error, subtitle }) => {
  let borderColor = theme.palette.border.main
  if (checked) {
    borderColor = theme.palette.primary.main
  }
  if (error) {
    borderColor = theme.palette.error.main
  }
  return {
    ...(subtitle && { alignItems: 'flex-start' }),
    borderColor,
    borderRadius: '4px ',
    borderStyle: 'solid',
    borderWidth: '1px',
    justifyContent: 'start',
    padding: '10px 24px 10px 14px',
    transition: 'transform 0.2s ease-in-out',
    ':first-of-type': {
      margin: '0',
    },
    ':not(:first-of-type)': {
      margin: '12px 0 0',
    },
    '&:hover': {
      transform: 'scale(1.005)',
    },
    '&:focus': {
      borderColor: `1px solid red !important`,
    },
  }
})
