import { Box } from '@mui/material'
import { Formik, FormikProps } from 'formik'
import { TOBACCO_SUB_ADDICTIONS } from 'global.constants'
import { getFixedT } from 'i18next'
import { ReactElement } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { UniversalI18nNamespace } from 'universal-i18n-namespace'
import * as yup from 'yup'
import { Message } from 'yup'

import AddictionSelector from '~components/AddictionSelector'
import {
  ADDICTION_SELECTION_PARENT_ADDICTIONS,
  AddictionInformation,
  AddictionSelectionFormName,
  AddictionSelectionFormValues,
  AddictionSelectionParentAddiction,
} from '~components/AddictionSelectorBasePage/AddictionSelectorBasePage'
import Confidential from '~components/Confidential'
import PrimaryButton from '~components/PrimaryButton'
import { PageView } from '~services/analyticsService/analytics.service'
import { handleFormSubmit } from '~utils/helpers'

import './AddictionSelectorForm.scss'

/**
 * Addiction Focus
 *
 * Component that takes in addiction information and renders a list of addictions.
 */

interface AddictionSelectorProps {
  addictions: AddictionInformation[]
  multiSelect: boolean
  onSubmit: (values: AddictionSelectionFormValues) => void
}

const PAGE_NAME: PageView['pageName'] = 'substanceSelectionPage'

export default function AddictionSelectorForm({
  addictions,
  multiSelect,
  onSubmit,
}: AddictionSelectorProps): ReactElement {
  const { t: tRoot } = useTranslation()
  const tCommonErrors = getFixedT(
    null,
    UniversalI18nNamespace.Common,
    'form.errors.addictionSelection'
  )
  const tTobaccoFocus = getFixedT(
    null,
    UniversalI18nNamespace.Common,
    'tobaccoAddictionFocus'
  )

  const nicotineSubAddictionRequiredError = (
    <Trans
      i18nKey="common:form.errors.addictionSelection.nicotineSubAddictionRequired"
      components={{
        visuallyHidden: <span className="visually-hidden" />,
      }}
    />
  ) as unknown as Message

  const eCigarettesLearnEnabled =
    addictions.filter((addiction) => addiction.value === 'eCigarettesLearn')
      .length > 0

  const subAddictionsBaseValidation = yup.string().oneOf(TOBACCO_SUB_ADDICTIONS)

  const multiSelectValidationSchema = yup.object({
    [AddictionSelectionFormName.MultiAddictions]: yup
      .array()
      .of(yup.string().oneOf(ADDICTION_SELECTION_PARENT_ADDICTIONS))
      .min(1, tCommonErrors('addictionRequired')),
    [AddictionSelectionFormName.NicotineSubAddiction]:
      subAddictionsBaseValidation.when(
        AddictionSelectionFormName.MultiAddictions,
        {
          is: (values: AddictionSelectionParentAddiction[]) =>
            values.includes(AddictionSelectionParentAddiction.Tobacco),
          then: (schema) => schema.required(nicotineSubAddictionRequiredError),
          otherwise: (schema) => schema.notRequired(),
        }
      ),
  })
  const singleSelectValidationSchema = yup.object({
    [AddictionSelectionFormName.SingleAddiction]: yup
      .string()
      .oneOf(ADDICTION_SELECTION_PARENT_ADDICTIONS)
      .required(tCommonErrors('addictionRequired')),
    [AddictionSelectionFormName.NicotineSubAddiction]:
      subAddictionsBaseValidation.when(
        AddictionSelectionFormName.SingleAddiction,
        {
          is: (value: AddictionSelectionParentAddiction) =>
            value === AddictionSelectionParentAddiction.Tobacco,
          then: (schema) => schema.required(nicotineSubAddictionRequiredError),
          otherwise: (schema) => schema.notRequired(),
        }
      ),
  })

  const formikConfig = {
    initialValues: {
      // We cast to `TreatmentProgram` since we're validating it later with yup
      ...(multiSelect && {
        [AddictionSelectionFormName.MultiAddictions]:
          [] as AddictionSelectionFormValues[AddictionSelectionFormName.MultiAddictions],
      }),
      ...(!multiSelect && {
        [AddictionSelectionFormName.SingleAddiction]:
          undefined as AddictionSelectionFormValues[AddictionSelectionFormName.SingleAddiction],
      }),
      [AddictionSelectionFormName.NicotineSubAddiction]:
        undefined as AddictionSelectionFormValues[AddictionSelectionFormName.NicotineSubAddiction],
    },
    validationSchema: multiSelect
      ? multiSelectValidationSchema
      : singleSelectValidationSchema,
    onSubmit,
  }

  return (
    <Box className="pelago-addiction-selector-form">
      <Formik {...formikConfig}>
        {(props: FormikProps<AddictionSelectionFormValues>) => (
          <form
            onSubmit={async (e) => {
              await handleFormSubmit(e, PAGE_NAME, props)
              if (
                (multiSelect &&
                  !props.values[
                    AddictionSelectionFormName.MultiAddictions
                  ]?.includes(AddictionSelectionParentAddiction.Tobacco)) ||
                (!multiSelect &&
                  props.values[AddictionSelectionFormName.SingleAddiction] !==
                    AddictionSelectionParentAddiction.Tobacco)
              ) {
                props.setFieldTouched(
                  AddictionSelectionFormName.NicotineSubAddiction,
                  false
                )
              }
            }}
          >
            <AddictionSelector
              addictions={addictions}
              multiSelect={multiSelect}
            ></AddictionSelector>

            {eCigarettesLearnEnabled && (
              <p className="pel-typography-b3">
                {tTobaccoFocus('otherTobaccoProducts')}
              </p>
            )}

            <Box className="pelago-addiction-selector-form__submit-button">
              <PrimaryButton
                type="submit"
                data-testid="submitButton"
                fullWidth={true}
              >
                {tRoot('common:global.continue')}
              </PrimaryButton>
              <Confidential></Confidential>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  )
}
