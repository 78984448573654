import { Checkbox, Collapse, FormGroup, Radio, RadioGroup } from '@mui/material'
import { useFormikContext } from 'formik'
import { ChangeEvent, ReactElement, useEffect, useState } from 'react'

import {
  AddictionInformation,
  AddictionSelectionFormValues,
  AddictionSelectionParentAddiction,
} from '~components/AddictionSelectorBasePage/AddictionSelectorBasePage'
import FormHelperText from '~components/FormHelperText'
import SelectLayout from '~components/SelectLayout'
import { trackAddictionChange } from '~services/analyticsService/analytics.service'

interface AddictionSelectorProps {
  addictions: AddictionInformation[]
  multiSelect: boolean
  isSubSelector?: boolean
}

/**
 * AddictionSelector
 *
 * A single-select (MUI radio) or multi-select (MUI checkbox) addiction list, allowing a user
 * to select their chosen addiction program and sub-addiction.
 *
 * TODO: [FLOW-154] Check and improve sub addiction accessibility.
 *
 */

export default function AddictionSelector(
  props: AddictionSelectorProps
): ReactElement {
  const { addictions, multiSelect, isSubSelector = false } = props
  const { values, errors, touched } =
    useFormikContext<AddictionSelectionFormValues>()

  const assumedFormName = addictions?.[0]?.formName
  const helperTextId = assumedFormName + '-helper-text'

  const helperTextProps = {
    'aria-describedby': helperTextId,
    'aria-errormessage': helperTextId,
  }

  return (
    <>
      {multiSelect ? (
        <FormGroup
          {...helperTextProps}
          aria-invalid={!!errors[assumedFormName]}
        >
          {addictions.map((addiction) => (
            <AddictionSelectorChild
              key={addiction.id}
              addiction={addiction}
              isSubSelector={isSubSelector}
              multiSelect={multiSelect}
              selected={
                values[addiction.formName]?.includes(
                  addiction.value as AddictionSelectionParentAddiction
                ) || false
              }
              error={
                !!touched[addiction.formName] && !!errors[addiction.formName]
              }
              helperTextId={helperTextId}
            ></AddictionSelectorChild>
          ))}
        </FormGroup>
      ) : (
        <RadioGroup
          {...helperTextProps}
          aria-invalid={!!errors[assumedFormName]}
        >
          {addictions.map((addiction) => (
            <AddictionSelectorChild
              key={addiction.id}
              addiction={addiction}
              isSubSelector={isSubSelector}
              multiSelect={multiSelect}
              selected={values[addiction.formName] === addiction.value}
              error={
                !!touched[addiction.formName] && !!errors[addiction.formName]
              }
            ></AddictionSelectorChild>
          ))}
        </RadioGroup>
      )}
      <FormHelperText id={helperTextId} error={!!errors[assumedFormName]}>
        {touched[assumedFormName] && !!errors[assumedFormName]
          ? errors[assumedFormName]
          : ''}
      </FormHelperText>
    </>
  )
}

function AddictionSelectorChild({
  addiction,
  isSubSelector,
  multiSelect,
  selected,
  error,
  helperTextId,
}: {
  addiction: AddictionInformation
  isSubSelector: boolean
  multiSelect: boolean
  selected: boolean
  error: boolean
  helperTextId?: string
}) {
  const { handleChange } = useFormikContext<AddictionSelectionFormValues>()

  const customHandleChange = (e: ChangeEvent<HTMLInputElement>) => {
    trackAddictionChange(e.target.value, e.target.checked)
    handleChange(e)
  }

  const addictionSubDrawerId = addiction.id + '-sub-drawer'

  const getParentProps = (isSelected: boolean, hasChildren: boolean) =>
    hasChildren
      ? {
          role: 'combobox',
          'aria-expanded': isSelected,
          'aria-controls': addictionSubDrawerId,
          'aria-haspopup': true,
        }
      : {}
  const [parentMenuItemProps, setParentMenuItemProps] = useState(
    getParentProps(selected, !!addiction.subAddictions?.length)
  )
  useEffect(() => {
    setParentMenuItemProps(
      getParentProps(selected, !!addiction.subAddictions?.length)
    )
  }, [addiction.subAddictions?.length, selected])

  return (
    <>
      {[
        <SelectLayout
          key={addiction.id}
          icon={addiction.icon}
          id={addiction.id}
          title={addiction.title}
          subtitle={addiction.description}
          testId={addiction.value}
          checked={selected}
          isSubLayout={isSubSelector}
          error={error}
        >
          {multiSelect ? (
            <Checkbox
              value={addiction.value}
              name={addiction.formName}
              color={error ? 'error' : 'primary'}
              onChange={customHandleChange}
              sx={{ svg: { height: '18px', width: '18px' } }}
              aria-describedby={helperTextId}
              aria-errormessage={helperTextId}
              {...parentMenuItemProps}
            />
          ) : (
            <Radio
              value={addiction.value}
              name={addiction.formName}
              color={error ? 'error' : 'primary'}
              onChange={customHandleChange}
              sx={{ svg: { height: '18px', width: '18px' } }}
              {...parentMenuItemProps}
            ></Radio>
          )}
        </SelectLayout>,
        addiction.subAddictions?.length ? (
          <SubDrawer
            key={addictionSubDrawerId}
            id={addictionSubDrawerId}
            addiction={addiction}
            open={!!addiction.subAddictions?.length && selected}
          ></SubDrawer>
        ) : null,
      ]}
    </>
  )
}

function SubDrawer({
  addiction,
  open,
  id,
}: {
  addiction: AddictionInformation
  open: boolean
  id: string
}) {
  return (
    <Collapse
      id={id}
      in={open}
      timeout="auto"
      sx={{
        borderLeft: '1px solid #CECECF',
        paddingLeft: '15px',
        margin: (open ? '15px' : '0') + ' 0 0 15px',
      }}
    >
      <AddictionSelector
        addictions={addiction.subAddictions || []}
        multiSelect={false}
        isSubSelector={true}
      ></AddictionSelector>
    </Collapse>
  )
}
