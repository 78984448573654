import {
  TobaccoSubAddictions,
  TreatmentProgram,
  TreatmentProgramConfig,
} from 'global.constants'
import { getFixedT } from 'i18next'
import { ReactElement } from 'react'
import { UniversalI18nNamespace } from 'universal-i18n-namespace'

import AddictionSelectorForm from '~components/AddictionSelectorForm'
import AlcoholIcon from '~icons/alcohol.svg?react'
import CannabisIcon from '~icons/cannabis.svg?react'
import CigarettesIcon from '~icons/cigarettes.svg?react'
import OpioidsIcon from '~icons/opioids.svg?react'
import StimulantsIcon from '~icons/stimulants.svg?react'
import { SendType, StateType } from '~utils/machineTypes'

export enum AddictionSelectionFormName {
  MultiAddictions = 'addictions',
  SingleAddiction = 'addiction',
  NicotineSubAddiction = 'nicotineSubAddiction',
}

export interface AddictionInformation {
  icon?: React.FunctionComponent<React.ComponentProps<'svg'>>
  title: string
  description: string
  id: string
  formName: AddictionSelectionFormName
  value: AddictionSelectionParentAddiction | TobaccoSubAddictions
  subAddictions?: AddictionInformation[]
}

export enum AddictionSelectionParentAddiction {
  Alcohol = 'alcohol',
  Cannabis = 'cannabis',
  Opioids = 'opioids',
  Stimulants = 'stimulants',
  Tobacco = 'tobacco',
}

export const ADDICTION_SELECTION_PARENT_ADDICTIONS: AddictionSelectionParentAddiction[] =
  [
    AddictionSelectionParentAddiction.Alcohol,
    AddictionSelectionParentAddiction.Cannabis,
    AddictionSelectionParentAddiction.Opioids,
    AddictionSelectionParentAddiction.Stimulants,
    AddictionSelectionParentAddiction.Tobacco,
  ]

/**
 * Main Addiction Focus
 *
 * Top-level component for the member to select an addiction they would like to focus on.
 */

interface AddictionSelectorBasePageProps {
  state: StateType
  send: SendType
}

export interface AddictionSelectionFormValues {
  [AddictionSelectionFormName.SingleAddiction]?:
    | AddictionSelectionParentAddiction
    | undefined
  [AddictionSelectionFormName.MultiAddictions]?: AddictionSelectionParentAddiction[]
  [AddictionSelectionFormName.NicotineSubAddiction]:
    | TobaccoSubAddictions
    | undefined
}

export default function AddictionSelectorBasePage({
  state,
  send,
}: AddictionSelectorBasePageProps): ReactElement {
  const t = getFixedT(null, UniversalI18nNamespace.Common, 'addictionFocus')
  const tTobaccoFocus = getFixedT(
    null,
    UniversalI18nNamespace.Common,
    'tobaccoAddictionFocus'
  )
  const multiSelect = state.context.company.polyaddictionEnabled
  const parentFormName = multiSelect
    ? AddictionSelectionFormName.MultiAddictions
    : AddictionSelectionFormName.SingleAddiction

  function onSubmit(data: AddictionSelectionFormValues) {
    send({
      type: 'SUBMIT_ADDICTION_SELECTION',
      value: data,
    })
  }

  const availableAddictions: TreatmentProgramConfig =
    state.context.availableTreatmentPrograms

  const addictions: AddictionInformation[] = []

  if (
    availableAddictions.cigarettes ||
    availableAddictions.cigarettesLearn ||
    availableAddictions.eCigarettes ||
    availableAddictions.eCigarettesLearn ||
    availableAddictions.chewingTobacco
  ) {
    const subAddictions: AddictionInformation[] = []
    const formName = AddictionSelectionFormName.NicotineSubAddiction

    if (availableAddictions.cigarettes || availableAddictions.cigarettesLearn) {
      subAddictions.push({
        title: tTobaccoFocus('cigarettesTitle'),
        description: tTobaccoFocus('cigarettesDescription'),
        id: 'cigarettes',
        value: availableAddictions.cigarettesLearn
          ? TreatmentProgram.CigarettesLearn
          : TreatmentProgram.Cigarettes,
        formName,
      })
    }

    if (
      availableAddictions.eCigarettes ||
      availableAddictions.eCigarettesLearn
    ) {
      subAddictions.push({
        title: tTobaccoFocus('eCigarettesTitle'),
        description: tTobaccoFocus('eCigarettesDescription'),
        id: 'e-cigarettes',
        value: availableAddictions.eCigarettesLearn
          ? TreatmentProgram.eCigarettesLearn
          : TreatmentProgram.eCigarettes,
        formName,
      })
    }

    if (availableAddictions.chewingTobacco) {
      subAddictions.push({
        title: tTobaccoFocus('chewingTobaccoTitle'),
        description: tTobaccoFocus('chewingTobaccoDescription'),
        id: 'chewing-tobacco',
        value: TreatmentProgram.ChewingTobacco,
        formName,
      })
    }
    addictions.push({
      icon: CigarettesIcon,
      title: t('tobaccoTitle'),
      description: t('tobaccoDescription'),
      id: 'tobacco',
      value: AddictionSelectionParentAddiction.Tobacco,
      subAddictions,
      formName: parentFormName,
    })
  }

  if (availableAddictions.alcohol) {
    addictions.push({
      icon: AlcoholIcon,
      title: t('alcoholTitle'),
      description: t('alcoholDescription'),
      id: 'alcohol',
      value: AddictionSelectionParentAddiction.Alcohol,
      formName: parentFormName,
    })
  }

  if (availableAddictions.opioids) {
    addictions.push({
      icon: OpioidsIcon,
      title: t('opioidsTitle'),
      description: t('opioidsDescription'),
      id: 'opioids',
      value: AddictionSelectionParentAddiction.Opioids,
      formName: parentFormName,
    })
  }

  if (availableAddictions.cannabis) {
    addictions.push({
      icon: CannabisIcon,
      title: t('cannabisTitle'),
      description: t('cannabisDescription'),
      id: 'cannabis',
      value: AddictionSelectionParentAddiction.Cannabis,
      formName: parentFormName,
    })
  }

  if (availableAddictions.stimulants) {
    addictions.push({
      icon: StimulantsIcon,
      title: t('stimulantsTitle'),
      description: t('stimulantsDescription'),
      id: 'stimulants',
      value: AddictionSelectionParentAddiction.Stimulants,
      formName: parentFormName,
    })
  }

  return (
    <AddictionSelectorForm
      addictions={addictions}
      multiSelect={multiSelect}
      onSubmit={onSubmit}
    />
  )
}
