import { Box, Checkbox, FormGroup } from '@mui/material'
import { useFormik, validateYupSchema, yupToFormErrors } from 'formik'
import { getFixedT } from 'i18next'
import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { UniversalI18nNamespace } from 'universal-i18n-namespace'
import * as yup from 'yup'

import InfoBanner from '~components/InfoBanner'
import Layout from '~components/Layout'
import PrimaryButton from '~components/PrimaryButton'
import SelectLayout from '~components/SelectLayout'
import { IncentiveType } from '~constants/types'
import { PageView } from '~services/analyticsService/analytics.service'
import {
  assertUnreachable,
  getIncentive,
  handleFormBlur,
  handleFormSubmit,
  isTobacco,
} from '~utils/helpers'
import { SendType, StateType } from '~utils/machineTypes'

import './MotivationalQuestion.scss'

interface MotivationalQuestionProps {
  state: StateType
  send: SendType
}

const motivationKeys = [
  'reduceUse',
  'goodHabits',
  'healthImprovements',
  'incentive',
  'undecided',
] as const

export enum Motivation {
  ReduceUse = 'Reduce Use',
  GoodHabits = 'Good Habits',
  HealthImprovements = 'Health Improvements',
  Incentive = 'Incentive',
  Undecided = 'Undecided',
}

const motivationsMap: Map<(typeof motivationKeys)[number], Motivation> =
  new Map([
    ['reduceUse', Motivation.ReduceUse],
    ['goodHabits', Motivation.GoodHabits],
    ['healthImprovements', Motivation.HealthImprovements],
    ['incentive', Motivation.Incentive],
    ['undecided', Motivation.Undecided],
  ])

type FormValues = {
  [key in (typeof motivationKeys)[number]]: boolean
}

const PAGE_NAME: PageView['pageName'] = 'motivationalQuestion'

export default function MotivationalQuestion({
  state,
  send,
}: MotivationalQuestionProps): ReactElement {
  const { selectedAddiction } = state.context
  const { t: tRoot } = useTranslation()
  const tPage = getFixedT(
    null,
    UniversalI18nNamespace.Common,
    'motivationalQuestion'
  )
  const tQuestions = getFixedT(
    null,
    UniversalI18nNamespace.Common,
    'motivationalQuestion.questions'
  )
  const tAddictions = getFixedT(
    null,
    UniversalI18nNamespace.Common,
    'addictions'
  )

  const shouldShowIncentive = (): boolean => {
    const incentive: IncentiveType | null = getIncentive()
    if (incentive) {
      /**
       * WARNING: when adding new case:
       *  - make sure that tracking is in sync with displaying ('incentiveQuestion' property in trackPageView method used in signupMachine)
       *  - please review if display value makes sense
       */
      switch (incentive) {
        case IncentiveType.AmazonGiftCard:
          return true
        default:
          assertUnreachable(incentive) // throws tsc error if we forget to handle every value from IncentiveType enum
      }
    } else {
      return false
    }
  }

  const filteredMotivationKeys = motivationKeys.filter((key) =>
    key === 'incentive' ? shouldShowIncentive() : true
  )

  const validationSchema = yup
    .object({
      reduceUse: yup.boolean(),
      goodHabits: yup.boolean(),
      healthImprovements: yup.boolean(),
      incentive: yup.boolean(),
      undecided: yup.boolean(),
    })
    .test('isFilled', (form, context) => {
      const isValid = Object.values(form).some((val) => val === true) // should have at least one selection
      return isValid ? true : context.createError({ path: 'form' }) // path is needed for formik to 'see' the form-level error
    })

  const formik = useFormik({
    initialValues: {
      reduceUse: false,
      goodHabits: false,
      healthImprovements: false,
      incentive: false,
      undecided: false,
    },
    validateOnMount: true,
    validateOnChange: true,
    validateOnBlur: true,
    validate: (values: FormValues) => {
      try {
        validateYupSchema<FormValues>(values, validationSchema, true)
      } catch (err) {
        return yupToFormErrors(err)
      }
      return {}
    },
    onSubmit: (formValues: FormValues) => {
      const motivations: Motivation[] = Object.entries(formValues)
        .filter(([, value]) => !!value)
        .map(
          ([key]) =>
            motivationsMap.get(
              key as (typeof motivationKeys)[number]
            ) as Motivation
        )

      send({
        type: 'UPDATE_MOTIVATION_DATA',
        value: motivations,
      })
    },
  })

  return (
    <Layout className="motivational-question">
      <form
        onBlur={(e) => handleFormBlur(e.target.name, PAGE_NAME)}
        onSubmit={(e) => handleFormSubmit(e, PAGE_NAME, formik)}
      >
        <h1 className="pel-typography-h5-bold">{tPage('pageHeader')}</h1>

        <InfoBanner
          header={tPage('infoBannerHeader')}
          content={tPage('infoBannerContent')}
          mb={2}
        ></InfoBanner>

        <FormGroup>
          {filteredMotivationKeys.map((option) => (
            <SelectLayout
              key={option}
              id={option}
              title={tQuestions(option, {
                addiction: tAddictions(
                  isTobacco(selectedAddiction)
                    ? 'tobacco'
                    : (selectedAddiction as string)
                ),
              })}
              checked={!!formik.values[option]}
              isSubLayout={false}
            >
              <Checkbox
                value={formik.values[option]}
                onChange={formik.handleChange}
                name={option}
              />
            </SelectLayout>
          ))}
        </FormGroup>
        <Box className="motivational-question__submit-button">
          <PrimaryButton
            type="submit"
            minwidth="100%"
            className={formik.isValid ? '' : 'Mui-disabled'}
            aria-disabled={!formik.isValid}
            data-testid="submit-button"
          >
            {tRoot('common:global.continue')}
          </PrimaryButton>
        </Box>
        <Box display="flex" justifyContent="center">
          <button
            className="pel-link-button-underline pel-margin-top-4"
            type="button"
            onClick={() => send({ type: 'UPDATE_MOTIVATION_DATA' })}
            data-testid="skip-button"
          >
            {tPage('skipQuestion')}
          </button>
        </Box>
      </form>
    </Layout>
  )
}
