import { Button, ButtonProps } from '@mui/material'
import { styled } from '@mui/material/styles'

/**
 * PrimaryButton
 *
 * Extends the default MUI button with our styling to create a large, blue
 * pill-style button
 */
const PrimaryButton = styled(Button, {
  name: 'PrimaryButton',
})<ButtonProps & { minwidth?: string }>(({ theme, minwidth = '317px' }) => ({
  minWidth: minwidth,
  minHeight: '54px',
  padding: '14px 16px',
  cursor: 'pointer',
  border: '2px solid',
  borderRadius: '8px',
  fontSize: '16px',
  lineHeight: '8px',
  fontFamily: theme.typography.fontFamily,
  fontWeight: 'bold',
  letterSpacing: '0.25px',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  textTransform: 'none',

  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:disabled, &.Mui-disabled': {
    backgroundColor: theme.palette.primary.light,
    color: '#fff',
  },
}))

export default PrimaryButton
