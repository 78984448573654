import { FlowVersion } from 'flow-version'
import { TreatmentProgramConfig } from 'global.constants'
import * as yup from 'yup'

import { Country, DataRegion } from '~constants/types'

export interface Company {
  country: Country
  dataRegion: DataRegion
  enabled: boolean
  enabledTreatmentPrograms: TreatmentProgramConfig
  id: number
  subCompanyId?: number
  name: string
  slug: string
  spanishEnabled: boolean
  requireSouthAfricaIDNumber: boolean
  gbEthnicity: boolean
  polyaddictionEnabled: boolean
  minorsProgramEnabled: boolean
  callChoiceEnabled?: boolean
  flowVersionsEnabled?: { [key in FlowVersion]?: boolean }
}

export type SignupContextCompany = Omit<
  Company,
  'id' | 'enabled' | 'spanishEnabled' | 'enabledTreatmentPrograms'
> & {
  // Using companyId instead of id as it is used in multiple places via
  // object destructuring and this way it will remain obvious that it is a
  // company id.
  companyId: Company['id']
  carrierId: string
  multipleLanguages: boolean
}

const baseCompanySchema = yup
  .object()
  .shape({
    country: yup.mixed<Country>().oneOf(Object.values(Country)).required(),
    dataRegion: yup
      .mixed<DataRegion>()
      .oneOf(Object.values(DataRegion))
      .required(),
    polyaddictionEnabled: yup.boolean().required(),
    minorsProgramEnabled: yup.boolean().required(),
    callChoiceEnabled: yup.boolean().optional().default(false),
    flowVersionsEnabled: yup.object(),
    subCompanyId: yup.number().optional().positive().integer(),
    name: yup.string().trim().required(),
    slug: yup.string().trim().required(),
    requireSouthAfricaIDNumber: yup.boolean().required(),
    requireVitalityIdNumber: yup.boolean().optional().default(false), // legacy property - not used
    gbEthnicity: yup.boolean().required(),
  })
  .noUnknown(true)
  .strict()

export const companySchema: yup.ObjectSchema<Company> =
  baseCompanySchema.concat(
    yup
      .object()
      .shape({
        enabled: yup.boolean().required(),
        enabledTreatmentPrograms: yup
          .object()
          .shape({
            alcohol: yup.boolean().required(),
            cannabis: yup.boolean().optional().default(false),
            chewingTobacco: yup.boolean().required(),
            cigarettes: yup.boolean().required(),
            eCigarettes: yup.boolean().required(),
            opioids: yup.boolean().required(),
            cigarettesLearn: yup.boolean().required(),
            eCigarettesLearn: yup.boolean().required(),
            stimulants: yup.boolean().optional().default(false),
          })
          .required(),
        id: yup.number().required().positive().integer(),
        spanishEnabled: yup.boolean().required(),
      })
      .noUnknown(true)
      .strict()
  )

export const signupContextCompanySchema: yup.ObjectSchema<SignupContextCompany> =
  baseCompanySchema.concat(
    yup
      .object()
      .shape({
        carrierId: yup.string().defined(),
        companyId: yup.number().required().positive().integer(),
        multipleLanguages: yup.boolean().required(),
        // deprecated, left to avoid validation error when rejoining the flow
        benefitHasCost: yup.boolean().optional().default(false),
        // deprecated, left to avoid validation error when rejoining the flow
        digitalOnboardingEnabled: yup.boolean().optional().default(false),
      })
      .noUnknown(true)
      .strict()
  )
