import { Box, BoxProps } from '@mui/material'
import { ReactElement } from 'react'

export type LayoutProps = {
  children: JSX.Element
  isCall?: boolean
} & BoxProps

/**
 * Layout
 */
export default function Layout(props: LayoutProps): ReactElement {
  const { children, isCall = false, ...boxProps } = props

  return isCall ? (
    <Box
      sx={{
        maxWidth: { xs: '320px', md: '600px' },
        margin: {
          xs: '24px auto 56px auto',
          sm: '24px auto 0 auto',
          lg: '64px auto 0 auto',
        },
      }}
      {...boxProps}
    >
      {children}
    </Box>
  ) : (
    <Box
      display="flex"
      alignItems="center"
      maxWidth="440px"
      mx="auto"
      px="25px"
      pb="25px"
      sx={{ marginTop: { xs: '24px', lg: '64px' } }}
      {...boxProps}
    >
      {children}
    </Box>
  )
}
