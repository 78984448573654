import { ReactElement, ReactNode } from 'react'

import Layout from '~components/Layout'
import PrimaryButton from '~components/PrimaryButton'

import './SimpleImagePage.scss'

export type SimpleImagePageProps = {
  heading: string | ReactNode
  paragraph: string | ReactNode
  imageElement: ReactNode
  buttonCopy?: string
  onButtonClick?: () => void
  imageBackgroundClass?: string
  hideButton?: boolean
}

/**
 * SimpleImagePage
 */
export default function SimpleImagePage(
  props: SimpleImagePageProps
): ReactElement {
  const {
    heading,
    paragraph,
    imageElement,
    hideButton = false,
    imageBackgroundClass = '',
    buttonCopy,
    onButtonClick,
  } = props

  return (
    <Layout>
      <div className="simple-image-page">
        <h1 className="simple-image-page__heading">{heading}</h1>
        <div
          className={
            'simple-image-page__image-container ' + imageBackgroundClass
          }
        >
          {imageElement}
        </div>
        <p className="simple-image-page__paragraph">{paragraph}</p>
        {!hideButton && (
          <PrimaryButton
            className="simple-image-page__submit-button"
            onClick={onButtonClick}
          >
            {buttonCopy}
          </PrimaryButton>
        )}
      </div>
    </Layout>
  )
}
