import ReactDOM from 'react-dom/client'

import {
  trackConnectivity,
  trackWindowNavigatedRefreshedClosed,
} from '~services/analyticsService/analytics.service'

import App from './components/App'
import { i18nInitPromise } from './i18n'

// Enable devtools if we're in dev mode
async function prepare() {
  if (import.meta.env.DEV) {
    await import('~services/devTools')
  }

  import('./services/analyticsService/loadAnalytics').then((loadAnalytics) => {
    loadAnalytics.default()
    window.addEventListener('beforeunload', () =>
      trackWindowNavigatedRefreshedClosed()
    )
    window.addEventListener('online', () => trackConnectivity(true))
    window.addEventListener('offline', () => trackConnectivity(false))
  })

  await i18nInitPromise

  return Promise.resolve()
}

// Log the current commit
console.info(`Current commit:`, __COMMIT_HASH__)

// Instantiate the React app
prepare().then(() =>
  ReactDOM.createRoot(document.getElementById('root') as Element).render(
    <App />
  )
)
