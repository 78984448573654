import { Box, FilledInput, FormControl, InputLabel } from '@mui/material'
import {
  BaseSingleInputFieldProps,
  DateValidationError,
  DesktopDatePicker,
  DesktopDatePickerProps,
  FieldSection,
  UseDateFieldProps,
} from '@mui/x-date-pickers'
import { useDateField } from '@mui/x-date-pickers/DateField/useDateField'
import {
  ChangeEventHandler,
  MouseEventHandler,
  ReactElement,
  ReactNode,
  Ref,
  forwardRef,
  useEffect,
  useState,
} from 'react'

import FormHelperText from '~components/FormHelperText'
import CalendarIcon from '~icons/calendar.svg?react'

import './DatePicker.scss'

// !!! this interface contains additional properties not written here
interface CustomFieldProps {
  clearable: boolean
  disabled?: boolean
  enableAccessibleFieldDOMStructure: boolean
  error: boolean
  focused?: boolean
  inputRef?: Ref<HTMLInputElement>
  InputProps?: {
    endAdornment?: ReactNode
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ref?: Ref<any>
  }
  label?: ReactNode
  onChange: ChangeEventHandler<HTMLInputElement>
  onClear: MouseEventHandler<Element>
  ownerState?: {
    slotProps?: {
      textField?: {
        error: boolean
        helperText: string
        name: string
        onBlur: () => void
      }
    }
  }
  value: string | number | readonly string[]
}

const CustomField = forwardRef(function CustomField(
  props: CustomFieldProps,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ref
): ReactElement {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    clearable,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    enableAccessibleFieldDOMStructure,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    error,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    focused,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    InputProps,
    inputRef,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onClear,
    ...other
  } = props

  const name = props.ownerState?.slotProps?.textField?.name
  const showError = props.ownerState?.slotProps?.textField?.error
  const helperText = props.ownerState?.slotProps?.textField?.helperText
  const onBlur = props.ownerState?.slotProps?.textField?.onBlur

  const labelId = name + '-label'
  const errorId = name + '-error-text'
  const ariaDescriptionProps = {
    'aria-labelledby': labelId,
    'aria-describedby': errorId,
  }

  const getErrorProps = (isError: boolean | undefined) =>
    isError ? { 'aria-errormessage': errorId, 'aria-invalid': true } : {}
  const [errorProps, setErrorProps] = useState(getErrorProps(showError))

  useEffect(() => {
    setErrorProps(getErrorProps(showError))
  }, [showError])

  return (
    <Box className="pel-PelagoDatePicker">
      <FormControl
        variant="filled"
        fullWidth={true}
        error={showError}
        ref={props.InputProps?.ref}
      >
        <InputLabel htmlFor={name} disabled={props.disabled} id={labelId}>
          {props.label}
        </InputLabel>

        <FilledInput
          name={name}
          value={props.value}
          disableUnderline={true}
          disabled={props.disabled}
          id={name}
          endAdornment={props.InputProps?.endAdornment}
          inputProps={{
            ...other,
            ...ariaDescriptionProps,
            ...errorProps,
            ref: inputRef,
          }}
          onBlur={onBlur}
        />
        <FormHelperText
          id={errorId}
          disabled={props.disabled}
          error={showError}
        >
          {helperText}
        </FormHelperText>
      </FormControl>
    </Box>
  )
})

interface CustomDateFieldProps
  extends UseDateFieldProps<Date, false>,
    BaseSingleInputFieldProps<
      Date | null,
      Date,
      FieldSection,
      false,
      DateValidationError
    > {}

function CustomDateField(props: CustomDateFieldProps) {
  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    slots,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    slotProps,
    ...textFieldProps
  } = props

  const response = useDateField<Date, false, typeof textFieldProps>({
    ...textFieldProps,
    enableAccessibleFieldDOMStructure: false,
  })

  return <CustomField {...response} />
}

export default function DatePicker(
  props: DesktopDatePickerProps<Date> & {
    slotProps: { field: { name: string } }
  }
) {
  return (
    <DesktopDatePicker
      views={['year', 'month', 'day']}
      {...props}
      slots={{
        field: CustomDateField,
        openPickerIcon: CalendarIcon,
        ...props.slots,
      }}
    />
  )
}
