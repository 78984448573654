import states from './states.yaml'

export interface USState {
  name: string
  code: string
  ageOfMajority: number
}

const usStates: USState[] = states

export default usStates
