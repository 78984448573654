import { AllTreatmentPrograms, TreatmentProgramConfig } from 'global.constants'

import { apiClient } from '~services/apiClient'
import esiCarrierService from '~services/esiCarrierService'

import fetchCompanyEligibility, {
  EligibilityResult,
} from './eligibilityService'

interface EsiELID {
  accessCode?: string
  carrierId?: string
  subCompanyId?: number
  dateOfBirth: string
  lastName: string
  zipCode: string
}

interface FetchEsiEligibilityResponse {
  eligibilityRules?: {
    hasCoDevice: boolean
    hasNrt: boolean
  }
  errorMessage?: string
  nextStep?: string
  results: {
    accessCode: string
    eligibilityRules: {
      hasCoDevice: boolean
      hasNrt: boolean
    }
    productId: string
  }[]
  success: boolean
}

/**
 * Fetch an Express Scripts user's eligibility record
 */
export default async function fetchEsiEligibility(
  elid: EsiELID
): Promise<EligibilityResult> {
  const esiCompanyId = 27
  const isEnableEsiLegacyEligibilityEndpointFeatureSwitch =
    import.meta.env.VITE_ENABLE_LEGACY_ESI_ELIGIBILITY_ENDPOINT === 'true'
  if (
    !isEnableEsiLegacyEligibilityEndpointFeatureSwitch ||
    (elid.subCompanyId &&
      esiCarrierService.carriersWithMultipleCarrierIds.some(
        (carrier) => carrier.companyId === elid.subCompanyId
      ))
  ) {
    return await fetchCompanyEligibility({
      slugCompanyId: esiCompanyId,
      companyId: elid.subCompanyId,
      dateOfBirth: elid.dateOfBirth,
      lastName: elid.lastName,
      postalCode: elid.zipCode,
      accessCode: elid.accessCode,
    })
  }
  const url = 'eligibility-api/checkGuestEligibility/27'
  return apiClient
    .put<FetchEsiEligibilityResponse>(url, {
      accessCode: elid.accessCode,
      carrierId: elid.carrierId,
      DOB: elid.dateOfBirth.replaceAll('-', ''),
      zipCode: elid.zipCode,

      // `lastName` should NOT be sent with `accessCode`
      ...(!elid.accessCode && { lastName: elid.lastName }),
    })
    .then((response) => {
      // TODO: Update if ESI adds cannabis/stimulants: https://quitgenius.atlassian.net/browse/COPS-1307?focusedCommentId=56610
      const treatmentAccessCode: TreatmentAccessCode = {
        alcohol: '',
        cigarettes: '',
        eCigarettes: '',
        opioids: '',
      }

      const treatmentPrograms = Object.fromEntries(
        AllTreatmentPrograms.map((p) => [p, false])
      ) as TreatmentProgramConfig

      response.data.results.forEach((v) => {
        switch (v.productId) {
          case '814':
            treatmentAccessCode.alcohol = v.accessCode
            treatmentPrograms.alcohol = true
            break
          case '812':
            treatmentAccessCode.cigarettes = v.accessCode
            treatmentAccessCode.eCigarettes = v.accessCode
            treatmentPrograms.cigarettes = true
            treatmentPrograms.eCigarettes = true
            break
          case '815':
            treatmentAccessCode.opioids = v.accessCode
            treatmentPrograms.opioids = true
            break
        }
      })

      return {
        companyId: response.data.success ? esiCompanyId : undefined,
        treatmentPrograms,
        treatmentAccessCode,
      }
    })
}
