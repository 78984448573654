import { AllFlowCopyFileNamespaces, CopyFileNamespace } from 'flow-version'
import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'
import { UniversalI18nNamespace } from 'universal-i18n-namespace'

export const i18nInitPromise = i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    debug: import.meta.env.DEV,
    ns: [UniversalI18nNamespace.Common, ...AllFlowCopyFileNamespaces],
    defaultNS: CopyFileNamespace.Original,
    fallbackLng: 'en-US',
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    load: 'currentOnly',
  })

export default i18n
