/**
 * The different versions of the registration flow.
 *
 * Used within the signup machine to determine the pages shown, their order and
 * the logic. Also used to set the i18n namespace for the correct copy.
 *
 * New versions should be added to {@link FlowVersionCopyRecord} and
 * {@link FlowVersionPriorityOrder}
 *
 * TODO: [FLOW-113] Separate ESI flow copy and add here.
 */
export enum FlowVersion {
  Original = 'original',
  LighterV1 = 'lighter_v1',
  HealthPlan = 'health_plan',
}

export const FlowVersions = Object.values(FlowVersion)

// TODO: [FLOW-113] If we add ESI as a flow version, it should be first.
export const FlowVersionPriorityOrder: FlowVersion[] = [
  FlowVersion.HealthPlan,
  FlowVersion.LighterV1,
  FlowVersion.Original,
]

/**
 *  Any {@link FlowVersion} that has a lighter-like (or lighter based) flow.
 *  Used within the signup machine to identify which flow versions should
 *  transition to lighter pages.
 */
export const LighterBasedFlowVersions: FlowVersion[] = [
  FlowVersion.HealthPlan,
  FlowVersion.LighterV1,
]

export enum CopyFileNamespace {
  Original = 'translation',
  Lighter = 'lighter',
}

const FlowVersionCopyRecord: Record<FlowVersion, CopyFileNamespace> = {
  [FlowVersion.Original]: CopyFileNamespace.Original,
  [FlowVersion.LighterV1]: CopyFileNamespace.Lighter,
  [FlowVersion.HealthPlan]: CopyFileNamespace.Lighter, // Could make its own namespace with lighter as fallback
}

export const AllFlowCopyFileNamespaces = Array.from(
  new Set(Object.values(FlowVersionCopyRecord)).values()
)

export const getCopyNamespaceByFlowVersion = (flowVersion: FlowVersion) => {
  return FlowVersionCopyRecord[flowVersion] || CopyFileNamespace.Original
}
