import esiCarrierConfig from './esiCarriers.yaml'
import { Carrier } from './schema'

const allCarriers: Carrier[] = esiCarrierConfig
const enabledCarriers: Carrier[] = allCarriers.filter(
  (carrier) => carrier.enabled
)
const carriersWithMultipleCarrierIds = allCarriers.filter(
  (carrier) => carrier.carrierIds.length > 1
)

interface EsiCarrierService {
  readonly allCarriers: Carrier[]
  readonly enabledCarriers: Carrier[]
  readonly carriersWithMultipleCarrierIds: Carrier[]
}

const esiCarrierService: EsiCarrierService = {
  allCarriers,
  enabledCarriers,
  carriersWithMultipleCarrierIds,
}

export default Object.freeze(esiCarrierService)
export type { Carrier }
