import { Country } from '~constants/types'

import { User } from './types/user'

export const INITIAL_USER: User = {
  accessCode: '',
  address: {
    address1: '',
    address2: '',
    city: '',
    country: Country.US,
    postalCode: '',
    province: '',
  },
  cellPhoneCountryCode: '+1',
  cellPhoneNumber: '',
  communicationsOptIn: false,
  dateOfBirth: '',
  email: '',
  ethnicity: '',
  firstName: '',
  language: 'en',
  lastName: '',
  password: '',
  planGroupNo: undefined,
  planMemberId: undefined,
  sex: undefined,
  genderPronouns: undefined,
  genderIdentity: undefined,
  isMinor: false,
  zaIdNumber: '',
}
