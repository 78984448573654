import { LinearProgress, LinearProgressProps } from '@mui/material'
import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import './ProgressBar.scss'

interface ProgressBarProps extends LinearProgressProps {
  value: number
}

export default function ProgressBar(props: ProgressBarProps): ReactElement {
  const { value } = props
  const { t } = useTranslation()

  return (
    <div className="progress-bar">
      <LinearProgress
        aria-label={`${t('common:global.signupProgress')} ${value}%`}
        color="primary"
        {...props}
      />
    </div>
  )
}
