import { Box, BoxProps } from '@mui/material'
import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import LockIcon from '~icons/lock.svg?react'

import './Confidential.scss'

export default function Confidential(props: BoxProps): ReactElement {
  const { t } = useTranslation()

  return (
    <Box
      {...props}
      className="pel-Confidential"
      data-testid="pelago-confidential"
    >
      <LockIcon className="pel-Confidential__icon"></LockIcon>
      <p className="pel-Confidential__typography">
        {t('common:global.confidential')}
      </p>
    </Box>
  )
}
