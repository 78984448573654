import { getFixedT } from 'i18next'
import React, { ReactElement, ReactNode } from 'react'
import { UniversalI18nNamespace } from 'universal-i18n-namespace'

import InfoIcon from '~icons/info.svg?react'

import './FormHelperText.scss'

/**
 * FormHelperText
 *
 * @component
 */

export interface FormHelperTextProps {
  disabled?: boolean
  error?: boolean
  id: string
  children: ReactNode
}

export default function FormHelperText(
  props: FormHelperTextProps & React.ComponentProps<'p'>
): ReactElement {
  const { error = true, disabled = false, children, ...restProps } = props
  const t = getFixedT(null, UniversalI18nNamespace.Common, 'global')

  return (
    <p
      className={
        'pelago-form-helper-text ' +
        (disabled ? 'disabled' : '') +
        (children ? '' : ' hidden')
      }
      {...(error && { 'aria-live': 'polite' })}
      {...restProps}
    >
      {error && children && (
        <span className="pelago-form-helper-text__icon">
          <InfoIcon
            className="pel-colour-error"
            height={12}
            width={12}
            aria-label={t('invalid')}
            data-testid="error-icon"
          />
          &nbsp;
        </span>
      )}
      <span>{children}</span>
    </p>
  )
}
