import { ReactElement } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { isTobaccoLearn, onlyTobaccoLearnProgramsEnabled } from '~utils/helpers'
import { SendType, StateType } from '~utils/machineTypes'

import './Footer.scss'

interface FooterProps {
  state: StateType
  send: SendType
}

const currentYear: number = new Date().getFullYear()

/**
 * Footer
 *
 * Global footer component
 */
export default function Footer({ state, send }: FooterProps): ReactElement {
  const { t } = useTranslation('common')
  const hideCareCoordinatorLink =
    onlyTobaccoLearnProgramsEnabled(state.context.availableTreatmentPrograms) ||
    isTobaccoLearn(state.context.selectedAddiction)

  return (
    <footer className="footer">
      <div className="footer__container">
        <div className="footer__top">
          <span className="pel-typography-b1-bold pel-colour-global-primary pel-margin-right-2 footer__top__header">
            {t('global.needHelp')}
          </span>
          <span>
            {hideCareCoordinatorLink ? (
              <Trans
                i18nKey="common:global.callUs.html"
                values={{ number: t('global.callUs.number') }}
                components={{
                  phoneLink: (
                    <a
                      href={'tel:' + t('global.callUs.number')}
                      className="footer__link"
                    ></a>
                  ),
                  outerElement: (
                    <span className="pel-typography-button-1 pel-colour-global-primary pel-typography-mod-weight-regular"></span>
                  ),
                }}
              />
            ) : (
              <>
                <button
                  className="footer__link footer__top__book-call"
                  type="button"
                  onClick={() => send({ type: 'TRANSITION_CARE_COORDINATOR' })}
                >
                  {t('global.bookCall')}
                </button>{' '}
                <span className="pel-typography-button-1 pel-colour-global-primary pel-typography-mod-weight-regular">
                  {t('global.withTeam')}
                </span>
              </>
            )}
          </span>
          <span className="footer__middot pel-colour-global-primary pel-margin-x-2">
            &middot;
          </span>
          <span>
            <Trans
              i18nKey="common:global.emailUs.html"
              values={{ email: t('global.emails.members') }}
              components={{
                emailLink: (
                  <a
                    href={'mailto:' + t('global.emails.members')}
                    className="footer__link"
                  ></a>
                ),
                outerElement: (
                  <span className="pel-typography-button-1 pel-colour-global-primary pel-typography-mod-weight-regular"></span>
                ),
              }}
            />
          </span>
        </div>
        <div className="footer__bottom">
          <div className="footer__bottom__left">
            <a
              href={t('global.docLinks.termsAndConditions')}
              target="_blank"
              className="footer__link footer__bottom__docs"
              rel="noreferrer"
            >
              {t('global.termsAndConditions')}
              <span className="visually-hidden">
                {t('global.opensInNewTab')}
              </span>
            </a>
            <a
              href={t('global.docLinks.privacyPolicy')}
              target="_blank"
              className="footer__link footer__bottom__docs"
              rel="noreferrer"
            >
              {t('global.privacyPolicy')}
              <span className="visually-hidden">
                {t('global.opensInNewTab')}
              </span>
            </a>
            <a
              href={t('global.docLinks.noticeOfPrivacyPractices')}
              target="_blank"
              className="footer__link footer__bottom__docs"
              rel="noreferrer"
            >
              {t('global.noticeOfPrivacyPractices')}
              <span className="visually-hidden">
                {t('global.opensInNewTab')}
              </span>
            </a>
          </div>

          <div className="footer__bottom__right">
            <span className="pel-typography-b3 pel-colour-global-primary">
              {t('global.copyright', {
                date: currentYear,
                company: t('common:global.companyNameLong'),
              })}
            </span>
          </div>
        </div>
      </div>
    </footer>
  )
}
