import { TreatmentProgramConfig } from 'global.constants'

import { apiClient } from '~services/apiClient'

interface StateResponse {
  isAlcoholLive: boolean
  isOpioidsLive: boolean
  success: boolean
}

/**
 * Hits the B2B states API endpoint to determine which treatment programs are available in the user's state.
 *
 * Changes here should be considered within the E2E tests, particularly those
 * using  the step definition:
 * `The state {string} will have the following availability endpoint response`
 */
export default function fetchTreatmentProgramsForState(
  state: string
): Promise<TreatmentProgramConfig> {
  const url = `/b2b/states/${state}`
  return apiClient.get<StateResponse>(url).then((response) => ({
    alcohol: response.data.isAlcoholLive,
    cannabis: response.data.isAlcoholLive, // For Cannabis, we can use isAlcoholLive
    chewingTobacco: true,
    cigarettes: true,
    cigarettesLearn: true,
    eCigarettes: true,
    eCigarettesLearn: true,
    opioids: response.data.isOpioidsLive,
    stimulants: response.data.isAlcoholLive, // For Stimulants, we can use isAlcoholLive
  }))
}
