import axios, { AxiosInstance, InternalAxiosRequestConfig } from 'axios'

import { DataRegion } from '~constants/types'
import {
  trackApiRequestError,
  trackApiRequestStart,
  trackApiRequestSuccess,
} from '~services/analyticsService/analytics.service'
import { maskDataInURLForDatadog } from '~services/analyticsService/datadogHelpers.utils'

// Set default axios config
axios.defaults.timeout = 30000

// Set default headers
axios.defaults.headers.common['Content-Type'] = 'application/json'
axios.defaults.headers.common['Accept'] = 'application/json'
axios.defaults.headers.common['version'] = '1.15'
axios.defaults.headers.common['x-api-key'] = import.meta.env.VITE_QG_API_KEY

const apiClient = axios.create({ baseURL: import.meta.env.VITE_QG_API_HOST_US })

export { apiClient }

apiClient.interceptors.request.use(function (config) {
  trackApiRequestStart({
    endpointUrl: getFullUrlFromConfigForLogging(config),
    method: config.method,
  })
  return config
}, errorInterceptor)

apiClient.interceptors.response.use(function (response) {
  trackApiRequestSuccess({
    endpointUrl: getFullUrlFromConfigForLogging(response.config),
    method: response.config.method,
    status: response.status,
  })
  return response
}, errorInterceptor)

export function setDataRegion(
  dataRegion: DataRegion = DataRegion.US,
  client: AxiosInstance = apiClient
) {
  client.defaults.baseURL =
    dataRegion === DataRegion.EU
      ? import.meta.env.VITE_QG_API_HOST_EU
      : import.meta.env.VITE_QG_API_HOST_US
}

export function setApiKey(
  apiKey: string = import.meta.env.VITE_QG_API_KEY,
  client: AxiosInstance = apiClient
) {
  client.defaults.headers.common['x-api-key'] = apiKey
}

/** Generates a full URL from an Axios config object for logging purposes */
export function getFullUrlFromConfigForLogging(
  config?: InternalAxiosRequestConfig
): string {
  return maskDataInURLForDatadog((config?.baseURL || '') + (config?.url || ''))
}

// Type-safe handling of possible error responses from the API
function errorInterceptor(error: unknown) {
  if (axios.isAxiosError(error)) {
    trackApiRequestError({
      endpointUrl: getFullUrlFromConfigForLogging(error.config),
      error: {
        message: error.message,
        ...(error.response?.status && { status: error.response.status }),
      },
      method: error.config?.method,
    })
  } else if (error instanceof Error) {
    trackApiRequestError({
      error: {
        message: error.message,
      },
    })
  } else {
    trackApiRequestError({
      error: {
        message: JSON.stringify(error) || 'unknown error',
      },
    })
  }
  return Promise.reject(error)
}
