import {
  RumActionEventDomainContext,
  RumErrorEventDomainContext,
  RumFetchResourceEventDomainContext,
  RumLongTaskEventDomainContext,
  RumOtherResourceEventDomainContext,
  RumViewEventDomainContext,
  RumXhrResourceEventDomainContext,
} from '@datadog/browser-rum'

const privacyReplacements: {
  searchValue: RegExp | string
  replaceValue: string
}[] = [
  { searchValue: /fullname=([^&]*)/g, replaceValue: 'fullname=*' }, // Disclose Data Consent pdf endpoint
]
export function maskDataInURLForDatadog(url: string): string {
  return privacyReplacements.reduce(
    (accumulator, currentValue) =>
      accumulator.replaceAll(
        currentValue.searchValue,
        currentValue.replaceValue
      ),
    url
  )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function maskDataInEvents(event: any): void {
  if (event?.view?.referrer) {
    event.view.referrer = maskDataInURLForDatadog(event.view.referrer)
  }
  if (event?.view?.url) {
    event.view.url = maskDataInURLForDatadog(event.view.url)
  }
  if (event?.resource?.url) {
    event.resource.url = maskDataInURLForDatadog(event.resource.url)
  }
  if (event?.locationHref) {
    event.locationHref = maskDataInURLForDatadog(event.locationHref)
  }
  if (event?.http?.url) {
    event.http.url = maskDataInURLForDatadog(event.http.url)
  }
  if (event?.message) {
    event.message = maskDataInURLForDatadog(event.message)
  }
}

export function maskDataInContext(
  context:
    | RumViewEventDomainContext
    | RumActionEventDomainContext
    | RumFetchResourceEventDomainContext
    | RumXhrResourceEventDomainContext
    | RumOtherResourceEventDomainContext
    | RumErrorEventDomainContext
    | RumLongTaskEventDomainContext
): void {
  try {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { xhr, location, performanceEntry } = context as any
    if (performanceEntry?.name) {
      performanceEntry.name = maskDataInURLForDatadog(performanceEntry.name)
    }
    if (location?.href) {
      location.href = maskDataInURLForDatadog(location.href)
    }
    if (location?.pathname) {
      location.pathname = maskDataInURLForDatadog(location.pathname)
    }
    if (xhr?.responseURL) {
      xhr.responseURL = maskDataInURLForDatadog(location.responseURL)
    }
    // eslint-disable-next-line no-empty
  } catch {}
}
