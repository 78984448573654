const BLACK_DEFAULT = '#212633'
const YELLOW_DEFAULT = '#fae355'
const GREEN_DEFAULT = '#198639'
const ORANGE_DEFAULT = '#f79009'
const RED_DEFAULT = '#f04438'

const colours = {
  black: {
    default: BLACK_DEFAULT,
    '50': '#fdfdfd',
    '400': '#909399',
    '700': '#373c47',
    '800': BLACK_DEFAULT,
    '950': '#000',
  },
  yellow: {
    default: YELLOW_DEFAULT,
    '100': '#fef7ce',
    '200': '#fcea8e',
    '300': YELLOW_DEFAULT,
    '500': '#e0cb58',
  },
  green: {
    default: GREEN_DEFAULT,
    '100': '#edfff2',
    '400': '#32d583',
    '500': '#22b04c',
    '700': GREEN_DEFAULT,
    '800': '#14662c',
  },
  orange: {
    default: ORANGE_DEFAULT,
    '100': '#fef0c7',
    '400': '#fec84b',
    '500': ORANGE_DEFAULT,
    '700': '#b54708',
  },
  red: {
    default: RED_DEFAULT,
    '50': '#fef3f2',
    '300': '#fda29b',
    '500': RED_DEFAULT,
    '600': '#d02f15',
    '700': '#b42318',
  },
}

const themeColours = {
  primary: {
    black: {
      default: colours.black.default,
      hover: colours.black['700'],
      pressed: colours.black['950'],
      disabled: colours.black['400'],
    },
    white: {
      default: '#fff',
    },
  },
  secondary: {
    yellow: {
      default: colours.yellow.default,
      hover: colours.yellow['200'],
      pressed: colours.yellow['500'],
      disabled: colours.yellow['100'],
    },
  },
  alert: {
    success: {
      default: colours.green.default,
      hover: colours.green['500'],
      pressed: colours.green['800'],
      disabled: colours.green['100'],
    },
    warning: {
      default: colours.orange.default,
      hover: colours.orange['400'],
      pressed: colours.orange['700'],
      disabled: colours.orange['100'],
    },
    error: {
      default: colours.red.default,
      hover: colours.red['300'],
      pressed: colours.red['700'],
      disabled: colours.red['50'],
    },
  },
}

const textColours = {
  global: {
    primary: themeColours.primary.black.default,
    secondary: '#6f7070',
    disabled: '#e5e6ea',
    light: '#fff',
  },
  button: {
    primary: '#fff',
    secondary: '#fff',
    alert: '#fff',
  },
  inform: {
    primary: themeColours.primary.black.default,
  },
  alert: {
    success: themeColours.alert.success.default,
    warning: themeColours.alert.warning.default,
    error: colours.red['600'],
  },
}

const dividerColour = '#cccccc'

const outlineColours = {
  default: dividerColour,
  active: themeColours.primary.black.default,
  disabled: dividerColour,
  success: colours.green['400'],
  warning: themeColours.alert.warning.default,
  error: themeColours.alert.error.default,
}

const commonBackgroundColour = '#f2f2f2'

const backgroundColours = {
  light: '#ffffff',
  dark: '#121212',
  'mist-blue': '#e8eeff',
  cerulean: '#a4bdff',
  'light-peach': '#ffe9df',
  peach: '#fcd3bf',
  'light-punch-pink': '#f7dffe',
  'punch-pink': '#eebcff',
  'light-maroon': '#e4c1cf',
  maroon: '#722f49',
  'light-tea-green': '#eef8e2',
  'tea-green': '#ddf1c4',
  cream: '#fff4dc',
  yellow: '#fae355',
  'light-moss-green': '#dbe0d4',
  'moss-green': '#4d6525',
  'light-tan': '#ded4c7',
  tan: '#bda98c',
  'storm-grey': '#e4e6ec',
  'storm-blue': '#637298',
  'option-hover': commonBackgroundColour,
  'container-hover': commonBackgroundColour,
  'action-hover': commonBackgroundColour,
  disabled: `rgba(${commonBackgroundColour}, 0.5)`,
  error: themeColours.alert.error.disabled,
  warning: themeColours.alert.warning.disabled,
  success: themeColours.alert.success.disabled,
}

export default {
  ...themeColours,
  text: textColours,
  outline: outlineColours,
  background: backgroundColours,
}
