import { getFixedT } from 'i18next'
import { ReactElement } from 'react'
import { UniversalI18nNamespace } from 'universal-i18n-namespace'

import IconBean from '~components/IconBean'
import SimpleImagePage from '~components/SimpleImagePage'

export default function SuccessfullyRegistered(): ReactElement {
  const tPage = getFixedT(
    null,
    UniversalI18nNamespace.Common,
    'successfullyRegistered'
  )

  return (
    <SimpleImagePage
      heading={tPage('heading')}
      paragraph={tPage('paragraph')}
      imageBackgroundClass="pel-background-colour-light-tea-green"
      imageElement={<IconBean></IconBean>}
      hideButton={true}
    ></SimpleImagePage>
  )
}
