import React, { ReactElement } from 'react'

import IconBeanIcon from '~icons/icon-bean.svg?react'
import TickSvg from '~icons/tick.svg?react'

import './IconBean.scss'

export interface IconBeanProps {
  icon?: ReactElement
  parentClassName?: string
  beanClassName?: string
  iconClassName?: string
}

/**
 *
 * IconBean component.
 *
 * If used in the future an update should be considered. For example: an enum
 * and switch case could be used to show icons without needing to pass the
 * element itself. Also the ability to pass size without a class could be
 * useful.
 *
 */
export default function IconBean({
  icon = <TickSvg></TickSvg>,
  parentClassName = '',
  beanClassName = '',
  iconClassName = '',
}: IconBeanProps) {
  return (
    <div className={'icon-bean ' + parentClassName}>
      <IconBeanIcon
        className={'icon-bean__bean-svg ' + beanClassName}
      ></IconBeanIcon>
      {React.cloneElement(icon, {
        className: 'icon-bean__icon-svg ' + iconClassName,
      })}
    </div>
  )
}
