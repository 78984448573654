import { AuthService } from './authServices'

export { default as fetchTreatmentProgramsForState } from './stateProgramsService'

export const cognitoUpdatePhoneNumber = AuthService.cognitoUpdatePhoneNumber
export const confirmNewPassword = AuthService.confirmNewPassword
export const createLighterAccount = AuthService.createLighterAccount
export const lighterB2BSave = AuthService.lighterB2BSave
export const resendOTPCode = AuthService.resendOTPCode
export const resendPasswordResetEmail = AuthService.resendPasswordResetEmail
export const sendPasswordResetEmail = AuthService.sendPasswordResetEmail
export const signIn = AuthService.signIn
export const saveB2BDetails = AuthService.saveB2BDetails
export const saveMotivations = AuthService.saveMotivations
export const submitOTPCode = AuthService.submitOTPCode
export const createNewUserAccount = AuthService.createNewUserAccount
export const fetchStarterCallUrl = AuthService.fetchStarterCallUrl
export const fetchHealthieCareCoordinatorCallUrl =
  AuthService.fetchHealthieCareCoordinatorCallUrl
/**
 * @deprecated TODO: [FE-349] Nylas removal
 */
export const fetchNylasCareCoordinatorCallUrl =
  AuthService.fetchNylasCareCoordinatorCallUrl
export const updateUserDetails = AuthService.updateUserDetails
export const updateUserProgressIfNeeded = AuthService.updateUserProgressIfNeeded
export const getProgress = AuthService.getProgress
export const signOut = AuthService.signOut
export { default as fetchCompanyEligibility } from './eligibilityService'
export { default as fetchEsiEligibility } from './esiEligibilityService'
