import { getFixedT } from 'i18next'
import { ReactElement } from 'react'
import { Trans } from 'react-i18next'
import { UniversalI18nNamespace } from 'universal-i18n-namespace'

import SimpleImagePage from '~components/SimpleImagePage'
import PageNotFoundImg from '~images/ice-cream-fail.png'
import { onlyTobaccoLearnProgramsEnabled } from '~utils/helpers'
import { SendType, StateType } from '~utils/machineTypes'

interface InternalErrorProps {
  state: StateType
  send: SendType
}

export default function InternalError({
  state,
  send,
}: InternalErrorProps): ReactElement {
  const t = getFixedT(null, UniversalI18nNamespace.Common, 'internalError')
  const tGlobal = getFixedT(null, UniversalI18nNamespace.Common, 'global')
  const hideCareCoordinatorLink = onlyTobaccoLearnProgramsEnabled(
    state.context.availableTreatmentPrograms
  )

  const paragraph = (
    <>
      {t('errorOccurred')}
      {hideCareCoordinatorLink ? (
        <Trans
          i18nKey="common:global.callUs.html"
          values={{ number: tGlobal('callUs.number') }}
          components={{
            phoneLink: (
              <a
                href={'tel:' + tGlobal('callUs.number')}
                className="simple-image-page__link"
              ></a>
            ),
            outerElement: (
              <span className="pel-typography-button-1 pel-colour-global-primary pel-typography-mod-weight-regular"></span>
            ),
          }}
        />
      ) : (
        t('book')
      )}
    </>
  )

  return (
    <SimpleImagePage
      heading={t('pageNotFound')}
      paragraph={paragraph}
      imageElement={<img src={PageNotFoundImg} alt=""></img>}
      hideButton={hideCareCoordinatorLink}
      buttonCopy={t('bookCallButton')}
      onButtonClick={() => send({ type: 'TRANSITION_CARE_COORDINATOR' })}
    ></SimpleImagePage>
  )
}
