import { isBefore, parse, subDays } from 'date-fns'
import { FlowVersion } from 'flow-version'

import { hasAppUTMMedium } from '~utils/helpers'

export interface FlowVersionLocalStorageItem {
  flowVersion: FlowVersion
  timestamp: 'string'
}

export const FlowVersionLocalStorageKey = 'flowVersion'

/**
 * getFlowVersionFromLocalStorage
 *
 * Checks localStorage for previously saved flow version. If it was saved more
 * than 30 days ago then return undefined. If nothing saved, return undefined.
 *
 * @returns {FlowVersion| undefined}
 */
export const getFlowVersionFromLocalStorage: () =>
  | FlowVersion
  | undefined = () => {
  let parsedFlowVersionLocalStorageItem: FlowVersionLocalStorageItem | undefined

  // if item in local storage, try to parse item
  try {
    const item = localStorage.getItem(FlowVersionLocalStorageKey)
    parsedFlowVersionLocalStorageItem = item ? JSON.parse(item) : undefined
  } catch {
    parsedFlowVersionLocalStorageItem = undefined
  }

  // if parsed item, check expiry
  if (parsedFlowVersionLocalStorageItem) {
    let flowVersionLocalStorageItemExpired = true
    try {
      // convert timestamp to date
      const parsedDate = parse(
        parsedFlowVersionLocalStorageItem?.timestamp,
        'T',
        new Date()
      )
      // comparisonDate is 30 days previously
      const comparisonDate = subDays(new Date(), 30)
      // if storage item was saved beofre the comparison date then it is
      // expired
      flowVersionLocalStorageItemExpired =
        parsedDate && isBefore(parsedDate, comparisonDate)
    } catch {
      flowVersionLocalStorageItemExpired = true
    }

    // if expired then remove from storage and prepare to return undefined
    if (flowVersionLocalStorageItemExpired) {
      localStorage.removeItem('flowVersion')
      parsedFlowVersionLocalStorageItem = undefined
    }
  }
  return parsedFlowVersionLocalStorageItem?.flowVersion
}

/**
 * Closes window if the registration flow was opened in mobile app's webview
 */
export const closeWindowIfWebView: () => void = () => {
  if (hasAppUTMMedium()) {
    window.close()
  }
}
