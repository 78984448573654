export enum Country {
  GB = 'GB',
  US = 'US',
  ZA = 'ZA',
}

// Permitted data regions
export enum DataRegion {
  EU = 'eu-west-2',
  US = 'us-east-2',
}

export enum StateAvailability {
  Unavailable = 'unavailable',
  Partial = 'partial',
  Available = 'available',
}

export enum CallCopyVariation {
  Primary = 'primary',
  PartialState = 'partial_state_availability',
  UnavailableState = 'no_state_availability',
  Minors = 'minors',
}

/**
 * WARNING: This enum defines which incentives are handled in registration flow (affects displaying/tracking)
 * - when adding new value:
 *   - make sure that displaying is adjusted ("BookCall"/"MotivationalQuestion" components as of now) - they should error without changes
 *   - make sure that tracking is in sync with displaying ('incentiveQuestion'/'incentiveReminder' property in trackPageView method used in signupMachine)
 */
export enum IncentiveType {
  AmazonGiftCard = 'amazon-gift-card',
}
