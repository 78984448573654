import { Autocomplete, Box } from '@mui/material'
import { getFixedT } from 'i18next'
import { ReactElement, useEffect, useState } from 'react'

import TextField from '~components/TextField'
import usStates, { USState } from '~constants/USStates'

/**
 * StateSelector
 *
 * Provides a drop-down list of US states a user can pick from. This is a
 * controlled component, so it requires the `onChange` and `value` props.
 *
 * TODO: localise the state list
 *
 * @component
 */

interface StateSelectorProps {
  error?: boolean
  helperText: string
  onChange: (value: string) => void
  value: string
  label?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onBlur?: any
}

export default function StateSelector(props: StateSelectorProps): ReactElement {
  const t = getFixedT(null, null, 'form')

  const [inputValue, setInputValue] = useState('')
  const [state, setState] = useState<USState | null>(null)

  // We set the initial value here based on the state code prop
  useEffect(() => {
    setState(usStates.find((s) => s.code === props.value) || null)
  }, [])

  // For subsequent changes, we update the state code using the callback prop
  useEffect(() => {
    props.onChange(state?.code || '')
  }, [state])

  return (
    <Box>
      <Autocomplete
        disableClearable={!!state}
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option) =>
          option.name.toLocaleLowerCase() === state?.name?.toLocaleLowerCase()
        }
        id="province-selector"
        inputValue={inputValue}
        {...(props.onBlur ? { onBlur: props.onBlur } : {})}
        onChange={(_, value) => setState(value)}
        onInputChange={(_, newInputValue) => {
          if (newInputValue) {
            const foundState = usStates.find(
              (s) => s.name.toLowerCase() === newInputValue.toLowerCase()
            )
            if (foundState) {
              setState(foundState)
            }

            setInputValue(newInputValue)
          } else {
            setInputValue('')
          }
        }}
        filterOptions={(options, state) => {
          const trimmedInputValue = state.inputValue
            ?.trim()
            ?.toLocaleLowerCase()
          if (trimmedInputValue) {
            return options.filter(
              (option) =>
                option.name.toLocaleLowerCase().includes(trimmedInputValue) ||
                option.code.toLocaleLowerCase().includes(trimmedInputValue)
            )
          } else {
            return options
          }
        }}
        options={usStates}
        renderInput={(params) => (
          <TextField
            name="province"
            autoComplete="address-level1"
            error={props.error}
            helperText={props.helperText}
            label={props.label ? props.label : t('fields.province')}
            value={inputValue}
            inputProps={params.inputProps}
            InputProps={params.InputProps}
            data-testid="province"
          />
        )}
        value={state}
      />
    </Box>
  )
}
