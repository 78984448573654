import {
  TOBACCO_SUB_ADDICTIONS,
  TobaccoSubAddictions,
  TreatmentProgram,
  WorkingQuittingPriority,
} from 'global.constants'
import { Sender } from 'xstate'

import {
  AddictionSelectionFormName,
  AddictionSelectionParentAddiction,
} from '~components/AddictionSelectorBasePage/AddictionSelectorBasePage'

import {
  SubmitAddictionAutoSelectionEvent,
  SubmitAddictionSelectionEvent,
} from '../machine-types'
import { SignupContext } from '../types/signupContext'

export const submitAddictionSelection =
  (
    context: SignupContext,
    event: SubmitAddictionAutoSelectionEvent | SubmitAddictionSelectionEvent
  ) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (callback: Sender<any>) => {
    const multiSelect = context.company.polyaddictionEnabled
    const treatmentAccessCode = context.treatmentAccessCode
    const data = event.value
    const addictionOrder: TreatmentProgram[] = []
    if (multiSelect) {
      const multiSelectedAddictions = data[
        AddictionSelectionFormName.MultiAddictions
      ] as AddictionSelectionParentAddiction[]
      if (
        multiSelectedAddictions.includes(
          AddictionSelectionParentAddiction.Opioids
        )
      ) {
        addictionOrder.push(TreatmentProgram.Opioids)
      }
      if (
        multiSelectedAddictions.includes(
          AddictionSelectionParentAddiction.Stimulants
        )
      ) {
        addictionOrder.push(TreatmentProgram.Stimulants)
      }
      if (
        multiSelectedAddictions.includes(
          AddictionSelectionParentAddiction.Alcohol
        )
      ) {
        addictionOrder.push(TreatmentProgram.Alcohol)
      }
      if (
        multiSelectedAddictions.includes(
          AddictionSelectionParentAddiction.Cannabis
        )
      ) {
        addictionOrder.push(TreatmentProgram.Cannabis)
      }
      if (
        multiSelectedAddictions.includes(
          AddictionSelectionParentAddiction.Tobacco
        ) &&
        data[AddictionSelectionFormName.NicotineSubAddiction]
      ) {
        addictionOrder.push(
          data[AddictionSelectionFormName.NicotineSubAddiction]
        )
      }
      callback({
        type: 'SET_POLY_ADDICTIONS',
        value: addictionOrder,
      })
    } else {
      addictionOrder.push(
        (data[AddictionSelectionFormName.SingleAddiction] ===
        AddictionSelectionParentAddiction.Tobacco
          ? data[AddictionSelectionFormName.NicotineSubAddiction]
          : data[
              AddictionSelectionFormName.SingleAddiction
            ]) as TreatmentProgram
      )
    }

    const quittingPriority: WorkingQuittingPriority = Object.fromEntries(
      addictionOrder.map((addiction, index) => {
        const isTobacco = TOBACCO_SUB_ADDICTIONS.includes(addiction)
        const quitting: TreatmentProgram = isTobacco
          ? (data.nicotineSubAddiction as TobaccoSubAddictions)
          : addiction

        return [index + 1, { quitting }]
      })
    )

    callback({
      type: 'SET_QUITTING_PRIORITY',
      value: quittingPriority,
    })

    const addiction = addictionOrder[0]

    const addictionAccessCode =
      treatmentAccessCode[addiction as keyof TreatmentAccessCode]

    if (addictionAccessCode) {
      callback({
        type: 'SET_ACCESS_CODE',
        value: { accessCode: addictionAccessCode },
      })
    }
    callback({ type: 'SET_ADDICTION_TYPE', value: addiction })
  }
