import esiCarrierService from '~services/esiCarrierService'

import { baseCompanyConfigs } from './companies'
import { Company } from './schema'

const esiCompanyId = 27

const allCompanies: Company[] = baseCompanyConfigs.flatMap((company) =>
  company.id === esiCompanyId
    ? [
        company,
        ...esiCarrierService.allCarriers.map((carrier) => ({
          ...company,
          subCompanyId: carrier.companyId,
        })),
      ]
    : [company]
)
const enabledCompanies: Company[] = allCompanies.filter((c) => c.enabled)

interface CompanyService {
  readonly allCompanies: Company[]
  readonly enabledCompanies: Company[]
  getById(id: number): Company | undefined
}

const companyService: CompanyService = {
  allCompanies,
  enabledCompanies,
  getById: (id) =>
    enabledCompanies.find((c) => c.id === id || c.subCompanyId === id),
}

export default Object.freeze(companyService)
export type { Company }
