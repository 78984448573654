import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js'
import { AxiosError, AxiosResponse } from 'axios'
import { formatISO } from 'date-fns'
import { LighterBasedFlowVersions } from 'flow-version'
import {
  CallType,
  Quitting,
  TreatmentProgram,
  WorkingQuittingPriorityItem,
} from 'global.constants'
import { firstValueFrom, from, tap } from 'rxjs'

import { Country, DataRegion } from '~constants/types'
import {
  logMessage,
  trackApiRequestError,
  trackApiRequestStart,
  trackApiRequestSuccess,
  trackMemberId,
} from '~services/analyticsService/analytics.service'
import { apiClient } from '~services/apiClient'
import { setApiKey, setDataRegion } from '~services/apiClient/apiClient'
import { formatPhoneNumber, getSanitizedContext } from '~utils/helpers'
import { StateType } from '~utils/machineTypes'

import pkg from '../../../../package.json'
import {
  ConfirmNewPasswordEvent,
  LoginEvent,
  ResetPasswordEvent,
  SubmitOTPCode,
} from '../machine-types'
import { SignupMachineError } from '../signup-machine-error'
import {
  OtpCodeDeliveryMedium,
  ProgressSignupContext,
  SignupContext,
  progressSignupContextSchema,
} from '../types/signupContext'
import { OnboardingType, User } from '../types/user'
import getServiceDirectory, { Services } from './getServiceDirectory'

interface UserData {
  user: SignupContext['user']
  selectedAddiction: TreatmentProgram
  quittingPriority: SignupContext['quittingPriority']
  multipleLanguages: SignupContext['company']['multipleLanguages']
  companyId: SignupContext['company']['companyId']
  slug: SignupContext['company']['slug']
  sessionData: AmazonCognitoIdentity.CognitoUserSession
}

interface DetailsApiResponse {
  success: boolean
}

interface FetchHealthieUrlResponse {
  schedulerUrl: string
  type: CallType
}

/**
 * @deprecated TODO: [FE-349] Nylas removal
 */
interface FetchNylasUrlResponse {
  success: boolean
  schedulerUrl: string
  type: CallType
}

// QuittingPriority is used for communication with BE (look for WorkingQuittingPriority for other uses)
export interface QuittingPriority {
  [key: number]: {
    quitting: Quitting
    quittingSubCategory?: TreatmentProgram.ChewingTobacco
    ageAtEnrolment?: number
    state?: string
  }
}

interface B2BDetailsUserBase {
  additionalAttributes: { attribute: string; value: string }[]
  coDeviceAdapterType?: number | null
  country: Country
  dateOfBirth: string // format: yyyy-MM-dd
  deliveryAddressLine1: string
  deliveryAddressLine2: string
  deliveryAddressLine3: string
  deliveryCity: string
  deliveryCountry: Country
  deliveryPostalCode: string
  deliveryState: string
  email: string
  firstName: string
  gender: string
  hasSmartphoneAccess?: boolean | null
  howDidYouHearAboutUs?: number | null
  lastName: string
  memberId?: string | null
  state: string
  telephoneNumber: string
}

interface B2BDetailsUserPayload extends B2BDetailsUserBase {
  genderIdentity: string
  genderPronouns: string
  givesSmsPermission: boolean
  planGroupNo?: User['planGroupNo']
  planMemberId?: User['planMemberId']
}

export interface B2BDetailsUserResponse extends B2BDetailsUserBase {
  companyName: string
}

export interface B2BOnboardingProgress {
  userProgress?: {
    Items: {
      appVersion: string
      context: string
      date: string // format: ISO 8601 format (local time zone is UTC) - equivalent to "yyyy-MM-dd'T'HH':'mm':'ss'Z'"
      identityId: string
    }[]
    Count: number
    ScannedCount: number
  }
}

export const B2B_ONBOARDING_PROGRESS_URL = 'b2b-onboarding-progress/me/progress'

export class AuthService {
  /**
   * cognitoClientId and cognitoUserPoolId are needed for retrieving userPool and current cognito session (current logged in user)
   * They are region specific and fetched from backend
   * We set them inside getServicesAndSetApiClientValues using cacheCognitoConsts method
   * They are cleared after page refresh
   * NOTE: Changes can affect saving user progress (needed for rejoining the reg flow)
   */
  static cognitoClientId: string = ''
  /**
   * see comment for cognitoClientId
   */
  static cognitoUserPoolId: string = ''
  /**
   * see comment for cognitoClientId
   */
  static cognitoEndpoint: string = ''

  /**
   * Version for EmailOTP
   */
  private static createUserCognitoIdentity(
    user: {
      email: string // used for EmailOTP / EmailPassword signup
      firstName: string
      lastName: string
    },
    environment: {
      registrationPoolId: string
      registrationClientId: string
      registrationEndpoint: string
    }
  ): Promise<unknown>
  /**
   * Version for PhoneOTP
   */
  private static createUserCognitoIdentity(
    user: {
      cellPhoneCountryCode: string // used for PhoneOTP signup
      cellPhoneNumber: string // used for PhoneOTP signup
      firstName: string
      lastName: string
    },
    environment: {
      registrationPoolId: string
      registrationClientId: string
      registrationEndpoint: string
    }
  ): Promise<unknown>
  /**
   * Version for EmailPassword
   */
  private static createUserCognitoIdentity(
    user: {
      email: string // used for EmailOTP / EmailPassword signup
      password: string // used for EmailPassword signup
      firstName: string
      lastName: string
    },
    environment: {
      registrationPoolId: string
      registrationClientId: string
    }
  ): Promise<unknown>
  /**
   * Performs an action towards Cognito to store the users information in AWS
   *
   * @returns {Promise}
   */
  private static createUserCognitoIdentity(
    user: {
      cellPhoneCountryCode?: string // used for PhoneOTP signup
      cellPhoneNumber?: string // used for PhoneOTP signup
      email?: string // used for EmailOTP / EmailPassword signup
      password?: string // used for EmailPassword signup
      firstName: string
      lastName: string
    },
    environment: {
      registrationPoolId: string
      registrationClientId: string
      registrationEndpoint?: string // used for PhoneOTP / EmailOTP
    }
  ) {
    const {
      cellPhoneCountryCode,
      cellPhoneNumber,
      email,
      password,
      firstName,
      lastName,
    } = user

    const { registrationPoolId, registrationClientId, registrationEndpoint } =
      environment

    const phoneNumberFormatted: string =
      cellPhoneCountryCode && cellPhoneNumber
        ? formatPhoneNumber(cellPhoneCountryCode, cellPhoneNumber)
        : ''

    const userPool = new AmazonCognitoIdentity.CognitoUserPool({
      UserPoolId: registrationPoolId,
      ClientId: registrationClientId,
      ...(registrationEndpoint && { endpoint: registrationEndpoint }),
      Storage: window.sessionStorage,
    })

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const url = (userPool as any).client.endpoint

    trackApiRequestStart({
      endpointUrl: url,
      method: 'put',
      name: 'Start creating Cognito identity',
    })

    const userAttributes: AmazonCognitoIdentity.CognitoUserAttribute[] = [
      new AmazonCognitoIdentity.CognitoUserAttribute({
        Name: 'name',
        Value: firstName,
      }),
      new AmazonCognitoIdentity.CognitoUserAttribute({
        Name: 'family_name',
        Value: lastName,
      }),
      new AmazonCognitoIdentity.CognitoUserAttribute({
        Name: 'custom:version',
        Value: '1.15',
      }),
    ]

    if (email) {
      userAttributes.push(
        new AmazonCognitoIdentity.CognitoUserAttribute({
          Name: 'email',
          Value: email,
        })
      )
    } else if (phoneNumberFormatted) {
      userAttributes.push(
        new AmazonCognitoIdentity.CognitoUserAttribute({
          Name: 'phone_number',
          Value: phoneNumberFormatted,
        })
      )
    }

    return new Promise((resolve, reject) => {
      userPool.signUp(
        email ? email : phoneNumberFormatted,
        password || crypto.randomUUID(),
        userAttributes,
        [],
        (error, response) => {
          if (error) {
            const resError: ResponseError = error

            trackApiRequestError({
              endpointUrl: url,
              error: {
                message: resError.message,
                status: resError.status,
              },
              method: 'post',
              name: 'Error cognito identity',
            })

            if (
              resError.code &&
              resError.code.includes('UsernameExistsException')
            ) {
              reject({ emailInUse: true })
            } else if (
              resError.code === 'InvalidParameterException' &&
              resError.message?.includes('password')
            ) {
              reject({ passwordLength: true })
            } else {
              reject({
                message: SignupMachineError.InternalError,
              })
            }
          }

          trackApiRequestSuccess({
            endpointUrl: url,
            method: 'post',
            name: 'Created cognito identity',
          })

          if (response?.userSub) {
            // NB: A member's ID is not the same as the Cognito user sub, however at the time
            // of registration these two happen to be the same
            trackMemberId(response.userSub)
          }

          resolve({ email, password })
        },
        {
          ...(registrationEndpoint && { signUpFlow: 'otp' }),
        }
      )
    })
  }

  /**
   * Version for EmailOTP
   */
  private static fetchCognitoUserSession(
    user: {
      email: string
    },
    environment: {
      registrationPoolId: string
      registrationClientId: string
      registrationEndpoint: string
    }
  ): Promise<AmazonCognitoIdentity.CognitoUserSession>
  /**
   * Version for PhoneOTP
   */
  private static fetchCognitoUserSession(
    user: {
      cellPhoneCountryCode: string
      cellPhoneNumber: string
    },
    environment: {
      registrationPoolId: string
      registrationClientId: string
      registrationEndpoint: string
    }
  ): Promise<AmazonCognitoIdentity.CognitoUserSession>
  /**
   * Version for EmailPassword
   */
  private static fetchCognitoUserSession(
    user: {
      password: string
      email: string
    },
    environment: {
      registrationPoolId: string
      registrationClientId: string
    }
  ): Promise<AmazonCognitoIdentity.CognitoUserSession>
  /**
   * fetchCognitoUserSession
   *
   * Logs the user into Cognito to create authentication tokens
   *
   * @returns {Promise}
   */
  private static fetchCognitoUserSession(
    user: {
      cellPhoneCountryCode?: string // used for PhoneOTP
      cellPhoneNumber?: string // used for PhoneOTP
      password?: string // used for EmailPassword
      email?: string // used for EmailOTP / EmailPassword
    },
    environment: {
      registrationPoolId: string
      registrationClientId: string
      registrationEndpoint?: string // used for EmailOTP / PhoneOTP
    }
  ): Promise<AmazonCognitoIdentity.CognitoUserSession> {
    const phoneNumberFormatted: string =
      user.cellPhoneCountryCode && user.cellPhoneNumber
        ? formatPhoneNumber(user.cellPhoneCountryCode, user.cellPhoneNumber)
        : ''

    const userPool = new AmazonCognitoIdentity.CognitoUserPool({
      UserPoolId: environment.registrationPoolId,
      ClientId: environment.registrationClientId,
      ...(environment.registrationEndpoint && {
        endpoint: environment.registrationEndpoint, // if we pass it for EmailPassword - InitiateAuth request fails with 500
      }),
      Storage: window.sessionStorage,
    })

    const authenticationDetails =
      new AmazonCognitoIdentity.AuthenticationDetails({
        Username: user.email ? user.email : phoneNumberFormatted,
        ...(user.password && { Password: user.password }),
        ...(environment.registrationEndpoint && {
          ClientMetadata: {
            signInOtpFlowVersion: 'V2',
          },
        }),
      })

    const cognitoUser = new AmazonCognitoIdentity.CognitoUser({
      Username: user.email ? user.email : phoneNumberFormatted,
      Pool: userPool,
      Storage: window.sessionStorage,
    })

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const url = (userPool as any).client.endpoint

    trackApiRequestStart({
      endpointUrl: url,
      method: 'post',
      name: 'Start fetching the cognito user session',
    })
    return new Promise((resolve, reject) => {
      const payload: [
        AmazonCognitoIdentity.AuthenticationDetails,
        AmazonCognitoIdentity.IAuthenticationCallback,
      ] = [
        authenticationDetails,
        {
          onSuccess: (session) => {
            trackApiRequestSuccess({
              endpointUrl: url,
              method: 'post',
              name: 'Fetched the cognito user session',
            })
            return resolve(session)
          },
          customChallenge: (customChallenge: {
            CustomChallengeSubType: string
            DeliveryMedium: OtpCodeDeliveryMedium
            Destination: string
          }) => {
            trackApiRequestSuccess({
              endpointUrl: url,
              method: 'post',
              name: 'OTP Code challenge requested',
            })
            reject({
              otp: {
                deliveryMedium: customChallenge.DeliveryMedium, // needed for copies
                destination: customChallenge.Destination, // needed for copies
                cognitoUser, // needed for sending OTP code
              },
            })
          },
          onFailure: (error) => {
            trackApiRequestError({
              endpointUrl: url,
              error: {
                message: error.message,
                status: error.status,
              },
              method: 'post',
              name: 'Error fetching the cognito user session',
            })

            return reject({
              message: SignupMachineError.InternalError,
            })
          },
        },
      ]

      environment.registrationEndpoint
        ? cognitoUser.initiateAuth(...payload)
        : cognitoUser.authenticateUser(...payload)
    })
  }

  private static forgotPassword = (
    email: string,
    poolId: string,
    clientId: string
  ): Promise<void> => {
    const userPool = new AmazonCognitoIdentity.CognitoUserPool({
      UserPoolId: poolId,
      ClientId: clientId,
      Storage: window.sessionStorage,
    })

    const cognitoUser = new AmazonCognitoIdentity.CognitoUser({
      Username: email,
      Pool: userPool,
      Storage: window.sessionStorage,
    })

    return new Promise<void>((resolve, reject) => {
      cognitoUser.forgotPassword({
        onSuccess: () => resolve(),
        onFailure: (error) => reject(error),
      })
    })
  }

  /**
   * submitUserData
   *
   * Submits the users details to the api using the auth data
   *
   * @returns {Promise}
   */
  private static submitUserData = async (data: UserData) => {
    const {
      user,
      selectedAddiction,
      quittingPriority,
      multipleLanguages,
      companyId,
      sessionData,
    } = data

    const jwtToken = sessionData.getAccessToken().getJwtToken()
    try {
      await Promise.all([
        this.postUserDetails(
          selectedAddiction,
          quittingPriority,
          multipleLanguages,
          user.language,
          jwtToken
        ),
        this.putB2BDetails(user, companyId, jwtToken),
      ])

      return Promise.resolve()
    } catch (error) {
      return Promise.reject({
        message: SignupMachineError.InternalError,
      })
    }
  }

  /**
   * postUserDetails
   *
   * Function that does a POST request to pass some user details to the user API
   *
   * @returns {Promise}
   */
  private static async postUserDetails(
    selectedAddiction: TreatmentProgram,
    rawQuittingPriority: SignupContext['quittingPriority'],
    multipleLanguages: boolean,
    language: Language,
    jwtToken: string
  ) {
    const url = '/user'

    /*
      - If the user has selected a Learn program, we remove the `Learn` suffix
        from the selected addiction.
      - If the user has selected `chewingTobacco`, this is saved as a
        sub-category of `eCigarettes`.
    */
    const getQuittingAndSubCategory = (selectedAddiction: TreatmentProgram) => {
      let quitting: Quitting
      let quittingSubCategory: TreatmentProgram.ChewingTobacco | null = null

      switch (selectedAddiction) {
        case TreatmentProgram.CigarettesLearn:
          quitting = TreatmentProgram.Cigarettes
          break
        case TreatmentProgram.eCigarettesLearn:
          quitting = TreatmentProgram.eCigarettes
          break
        case TreatmentProgram.ChewingTobacco:
          quitting = TreatmentProgram.eCigarettes
          quittingSubCategory = TreatmentProgram.ChewingTobacco
          break
        default:
          quitting = selectedAddiction
      }

      return {
        quitting,
        ...(!!quittingSubCategory && {
          quittingSubCategory,
        }),
      }
    }

    const quittingPriority: QuittingPriority = {}
    if (rawQuittingPriority) {
      ;(
        Object.entries(rawQuittingPriority) as unknown as [
          number,
          WorkingQuittingPriorityItem,
        ][]
      ).forEach(([key, value]) => {
        quittingPriority[key] = {
          ...value,
          ...getQuittingAndSubCategory(value.quitting),
        }
      })
    }

    const userDetails = {
      ...getQuittingAndSubCategory(selectedAddiction),
      quittingPriority,
      lastSeen: Math.floor(Date.now() / 1000),
      ...(multipleLanguages && { quitCoachPreferredLanguage: language }),
    }

    return apiClient.post<DetailsApiResponse>(url, userDetails, {
      headers: { Authorization: jwtToken },
    })
  }

  private static getB2BDetailsUrl(companyId: string | number): string {
    return `b2b/companies/${companyId}/users/me`
  }

  /**
   * putB2BDetails
   *
   * Function that does a PUT request to pass the users details to the B2B company API
   *
   * @returns {Promise}
   */
  private static async putB2BDetails(
    user: UserData['user'],
    companyId: number,
    jwtToken: string
  ) {
    const url = this.getB2BDetailsUrl(companyId)

    // Join the country code (e.g. `+1`) to the rest of the number and remove
    // all characters apart from digits and `+`
    const telephoneNumber = (
      user.cellPhoneCountryCode + user.cellPhoneNumber
    ).replace(/[^0-9+]/g, '')

    const memberId = user.zaIdNumber
    const sanitizedEmail = user.email.toLowerCase()

    return apiClient.put<DetailsApiResponse>(
      url,
      {
        email: sanitizedEmail,
        firstName: user.firstName,
        lastName: user.lastName,
        dateOfBirth: user.dateOfBirth,
        state: user.address.province,
        gender: user.sex,
        givesSmsPermission: user.communicationsOptIn,
        deliveryAddressLine1: user.address.address1,
        deliveryAddressLine2: user.address.address2,
        deliveryAddressLine3: '',
        deliveryCity: user.address.city,
        deliveryState: user.address.province,
        deliveryCountry: user.address.country,
        deliveryPostalCode: user.address.postalCode,
        country: user.address.country,
        telephoneNumber,
        ...(user.genderIdentity && { genderIdentity: user.genderIdentity }),
        ...(user.genderPronouns && { genderPronouns: user.genderPronouns }),
        ...(user.planGroupNo && { planGroupNo: user.planGroupNo }),
        ...(user.planMemberId && { planMemberId: user.planMemberId }),
        ...(user.accessCode && { memberId: user.accessCode }),
        ...(memberId && { memberId }),
        ...(user.ethnicity && {
          additionalAttributes: [
            {
              attribute: 'gbEthnicity',
              value: user.ethnicity,
            },
          ],
        }),
        ...(user.onboardingType && {
          onboardingType: [
            OnboardingType.DigitalOnboarding,
            OnboardingType.VirtualAppointmentOnboarding,
          ].includes(user.onboardingType)
            ? OnboardingType.DigitalOnboardingPersonalizationQuiz
            : user.onboardingType,
        }),
      } as B2BDetailsUserPayload,
      { headers: { Authorization: jwtToken } }
    )
  }

  /**
   * fetchStarterCallUrl
   *
   * Function that fetches the url to book a starter call
   * for fallback it fetches care coordinator call
   * it returns healthie/nylas depending on feature switch
   *
   * @returns {Object}
   */
  public static fetchStarterCallUrl = (
    sessionData: AmazonCognitoIdentity.CognitoUserSession
  ) => {
    const isHealthieSchedulingEnabledFeatureSwitch =
      import.meta.env.VITE_ENABLE_HEALTHIE_SCHEDULING === 'true'

    // TODO: [FE-349] Nylas removal
    if (isHealthieSchedulingEnabledFeatureSwitch) {
      const url = '/scheduling/scheduling-page/onboarding/healthie/me'
      trackApiRequestStart({
        endpointUrl: url,
        method: 'get',
        name: 'Fetch starter call url',
      })
      return apiClient
        .get<FetchHealthieUrlResponse>(url, {
          headers: {
            Authorization: sessionData.getAccessToken().getJwtToken(),
          },
        })
        .then((response) => {
          if (response.data.schedulerUrl) {
            trackApiRequestSuccess({
              endpointUrl: url,
              method: 'get',
              name: 'Fetched starter call url',
            })
            return {
              healthieUrl: response.data.schedulerUrl,
              metadata: sessionData.getIdToken().payload.sub,
              type: response.data.type,
            }
          } else {
            throw new Error('UndefinedSchedulerUrl')
          }
        })
        .catch((error) => {
          trackApiRequestError({
            endpointUrl: url,
            error: {
              message: error?.message,
              status: error?.status,
            },
            method: 'get',
            name: 'Error fetching starter call url',
          })

          return this.fetchHealthieCareCoordinatorCallUrl().then(
            (response) => ({
              ...response,
              metadata: sessionData.getIdToken().payload.sub,
            })
          )
        })
        .catch((error) => {
          trackApiRequestError({
            error: {
              message: error?.message,
              status: error?.status,
            },
            method: 'get',
            name: 'Error fetching care coordinator call url in starter call fallback',
          })

          return { message: 'beInTouch' }
        })
    } else {
      const url = '/scheduling/scheduling-page/onboarding/me'
      trackApiRequestStart({
        endpointUrl: url,
        method: 'get',
        name: 'Fetch starter call url',
      })
      return apiClient
        .get<FetchNylasUrlResponse>(url, {
          headers: {
            Authorization: sessionData.getAccessToken().getJwtToken(),
          },
        })
        .then((response) => {
          if (response.data.schedulerUrl) {
            trackApiRequestSuccess({
              endpointUrl: url,
              method: 'get',
              name: 'Fetched starter call url',
            })
            return {
              nylasUrl: response.data.schedulerUrl,
              metadata: sessionData.getIdToken().payload.sub,
              type: response.data.type,
            }
          } else {
            throw new Error('UndefinedSchedulerUrl')
          }
        })
        .catch((error) => {
          trackApiRequestError({
            endpointUrl: url,
            error: {
              message: error?.message,
              status: error?.status,
            },
            method: 'get',
            name: 'Error fetching starter call url',
          })

          return this.fetchNylasCareCoordinatorCallUrl().then((response) => ({
            ...response,
            metadata: sessionData.getIdToken().payload.sub,
          }))
        })
        .catch((error) => {
          trackApiRequestError({
            error: {
              message: error?.message,
              status: error?.status,
            },
            method: 'get',
            name: 'Error fetching care coordinator call url in starter call fallback',
          })

          return { message: 'beInTouch' }
        })
    }
  }

  /**
   * Function that fetches the healthie url to book a care coordinator call
   *
   * @returns {Object}
   */
  public static fetchHealthieCareCoordinatorCallUrl = () => {
    const url = '/scheduling/scheduling-page/type/healthie/care-coordinator'
    trackApiRequestStart({
      endpointUrl: url,
      method: 'get',
      name: 'Fetch care coordinator call url',
    })
    return apiClient
      .get<FetchHealthieUrlResponse>(url)
      .then((response) => {
        if (response.data.schedulerUrl) {
          trackApiRequestSuccess({
            endpointUrl: url,
            method: 'get',
            name: 'Fetched care coordinator call url',
          })
          return {
            healthieUrl: response.data.schedulerUrl,
            type: response.data.type,
          }
        } else {
          throw new Error('UndefinedCareCoordinatorUrl')
        }
      })
      .catch((error) => {
        trackApiRequestError({
          endpointUrl: url,
          error: {
            message: error?.message,
            status: error?.status,
          },
          method: 'get',
          name: 'Error fetching care coordinator call url',
        })
        throw error
      })
  }

  /**
   * Function that fetches the nylas url to book a care coordinator call
   *
   * @returns {Object}
   * @deprecated TODO: [FE-349] Nylas removal
   */
  public static fetchNylasCareCoordinatorCallUrl = () => {
    const url = '/scheduling/scheduling-page/type/care-coordinator'
    trackApiRequestStart({
      endpointUrl: url,
      method: 'get',
      name: 'Fetch care coordinator call url',
    })
    return apiClient
      .get<FetchNylasUrlResponse>(url)
      .then((response) => {
        if (response.data.schedulerUrl) {
          trackApiRequestSuccess({
            endpointUrl: url,
            method: 'get',
            name: 'Fetched care coordinator call url',
          })
          return {
            nylasUrl: response.data.schedulerUrl,
            type: response.data.type,
          }
        } else {
          throw new Error('UndefinedCareCoordinatorUrl')
        }
      })
      .catch((error) => {
        trackApiRequestError({
          endpointUrl: url,
          error: {
            message: error?.message,
            status: error?.status,
          },
          method: 'get',
          name: 'Error fetching care coordinator call url',
        })
        throw error
      })
  }

  /**
   * createNewUserAccount
   *
   * Function that fires off requests to numerous endpoints to create the user
   *
   * @returns {Promise}
   */
  public static createNewUserAccount = async (context: SignupContext) => {
    const {
      user,
      user: { email, password, firstName, lastName },
      selectedAddiction,
      quittingPriority,
      company: { companyId, slug, multipleLanguages, dataRegion },
    } = context
    const sanitizedEmail = email.toLowerCase()

    try {
      setDataRegion(dataRegion)
      const services = await getServiceDirectory(dataRegion)

      if (!selectedAddiction || !services) {
        throw Error
      }

      setApiKey(services.apiKey)
      this.cacheCognitoConsts(services) // needed for Call Choice decision

      await this.createUserCognitoIdentity(
        { email: sanitizedEmail, password, firstName, lastName },
        {
          registrationPoolId: services.registrationPoolId,
          registrationClientId: services.registrationClientId,
        }
      )
      const sessionData = await this.fetchCognitoUserSession(
        { email: sanitizedEmail, password },
        {
          registrationPoolId: services.registrationPoolId,
          registrationClientId: services.registrationClientId,
        }
      )

      await this.submitUserData({
        user,
        selectedAddiction,
        quittingPriority,
        companyId,
        sessionData,
        slug,
        multipleLanguages,
      })

      const starterCallData = await this.fetchStarterCallUrl(sessionData)
      return Promise.resolve(starterCallData)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  /**
   * sessionRefresh
   *
   * @returns {Promise<AmazonCognitoIdentity.CognitoUserSession>}
   */
  private static sessionRefresh = async (services?: Services) => {
    return new Promise<AmazonCognitoIdentity.CognitoUserSession>(
      (resolve, reject) => {
        const userPool = new AmazonCognitoIdentity.CognitoUserPool({
          UserPoolId: services?.registrationPoolId || this.cognitoUserPoolId,
          ClientId: services?.registrationClientId || this.cognitoClientId,
          Storage: window.sessionStorage,
        })

        const cognitoUser = userPool.getCurrentUser()

        if (cognitoUser === null) {
          reject(new Error(SignupMachineError.NoUser))
        }

        cognitoUser?.getSession(
          (
            error: Error,
            session: AmazonCognitoIdentity.CognitoUserSession | null
          ) => {
            if (error) {
              reject(error)
            } else if (!session) {
              reject(new Error(SignupMachineError.NoSession))
            } else {
              resolve(session)
            }
          }
        )
      }
    )
  }

  private static getServicesAndSetApiClientValues = async (
    dataRegion: DataRegion
  ) => {
    setDataRegion(dataRegion)
    const services = await getServiceDirectory(dataRegion)

    if (!services) {
      throw Error(SignupMachineError.InternalError)
    }

    setApiKey(services.apiKey)
    this.cacheCognitoConsts(services)

    return services
  }

  public static resendPasswordResetEmail = async (context: SignupContext) => {
    const {
      resetPassword: { email, cognitoClientId, cognitoUserPoolId },
    } = context

    try {
      await this.forgotPassword(email, cognitoUserPoolId, cognitoClientId)
      return Promise.resolve()
    } catch (error) {
      return (error as Error).name === 'UserNotFoundException'
        ? Promise.resolve()
        : Promise.reject({
            message: SignupMachineError.ResetPasswordSthWentWrong,
          })
    }
  }

  public static sendPasswordResetEmail = async (
    context: SignupContext,
    event: ResetPasswordEvent
  ): Promise<{
    cognitoClientId: string
    cognitoUserPoolId: string
    email: string
  }> => {
    const {
      value: { email },
    } = event
    const sanitizedEmail = email.toLowerCase()
    let services: Services | undefined

    // region is not known at this stage so try first with logging in in US and then in EU
    for (const region of [DataRegion.US, DataRegion.EU]) {
      try {
        services = await this.getServicesAndSetApiClientValues(region)
        try {
          await this.forgotPassword(
            sanitizedEmail,
            services.registrationPoolId,
            services.registrationClientId
          )
          break // stops loop on success and resolves promise (outside of loop)
        } catch (error) {
          if ((error as Error).name === 'UserNotFoundException') {
            continue // goes to the next loop iteration if user not found in the region or resoves promise if tried all regions (outside of loop)
          } else {
            throw error
          }
        }
      } catch {
        return Promise.reject({
          message: SignupMachineError.ResetPasswordSthWentWrong,
        })
      }
    }

    // resolves in 2 cases: success OR user not found in all regions
    return Promise.resolve({
      cognitoClientId: services?.registrationClientId || '',
      cognitoUserPoolId: services?.registrationPoolId || '',
      email: sanitizedEmail,
    })
  }

  public static confirmNewPassword = async (
    context: SignupContext,
    event: ConfirmNewPasswordEvent
  ) => {
    const {
      resetPassword: { email, cognitoClientId, cognitoUserPoolId },
    } = context
    const {
      value: { verificationCode, password },
    } = event

    const userPool = new AmazonCognitoIdentity.CognitoUserPool({
      UserPoolId: cognitoUserPoolId,
      ClientId: cognitoClientId,
      Storage: window.sessionStorage,
    })

    const cognitoUser = new AmazonCognitoIdentity.CognitoUser({
      Username: email,
      Pool: userPool,
      Storage: window.sessionStorage,
    })

    return new Promise<void>((resolve, reject) => {
      cognitoUser.confirmPassword(verificationCode, password, {
        onSuccess: () => resolve(),
        onFailure: (error) => {
          switch (error.name) {
            case 'ExpiredCodeException':
              reject({ message: SignupMachineError.ResetPasswordExpiredCode })
              break
            case 'CodeMismatchException':
              reject({ message: SignupMachineError.ResetPasswordCodeMismatch })
              break
            case 'InvalidPasswordException':
              reject({
                message: SignupMachineError.ResetPasswordInvalidPassword,
              })
              break
            default:
              reject({ message: SignupMachineError.ResetPasswordSthWentWrong })
          }
        },
      })
    })
  }

  public static signIn = async (context: SignupContext, event: LoginEvent) => {
    const {
      value: { email, password },
    } = event
    const sanitizedEmail = email.toLowerCase()
    let services: Services

    const fetchSession = async () =>
      this.fetchCognitoUserSession(
        { email: sanitizedEmail, password },
        {
          registrationPoolId: services.registrationPoolId,
          registrationClientId: services.registrationClientId,
        }
      )

    // region is not known at this stage so try first with logging in in US and then in EU
    try {
      services = await this.getServicesAndSetApiClientValues(DataRegion.US)
      try {
        await fetchSession()
        return Promise.resolve()
      } catch {
        services = await this.getServicesAndSetApiClientValues(DataRegion.EU)
        try {
          await fetchSession()
          return Promise.resolve()
        } catch {
          return Promise.reject({ loginFailed: true })
        }
      }
    } catch (error) {
      return Promise.reject(error)
    }
  }

  /**
   * signOut
   *
   * @returns {void}
   */
  public static signOut = async ({
    company: { dataRegion },
  }: SignupContext) => {
    try {
      const services = await this.getServicesAndSetApiClientValues(dataRegion)

      const userPool = new AmazonCognitoIdentity.CognitoUserPool({
        UserPoolId: services.registrationPoolId,
        ClientId: services.registrationClientId,
        Storage: window.sessionStorage,
      })

      const cognitoUser = userPool?.getCurrentUser()

      if (!cognitoUser) {
        throw Error(SignupMachineError.InternalError)
      }

      cognitoUser?.signOut()
    } catch (e) {
      // TODO: [FLOW-218] Add error handling
    }
  }

  /**
   * createLighterAccount
   *
   * Function that fires off requests to create a cognito user and save user
   * sync details - NO B2B call
   *
   * @returns {Promise<void>}
   */
  public static createLighterAccount = async (context: SignupContext) => {
    const {
      user,
      user: {
        cellPhoneCountryCode,
        cellPhoneNumber,
        email,
        firstName,
        lastName,
        password,
      },
      selectedAddiction,
      quittingPriority,
      company: { multipleLanguages, dataRegion },
    } = context
    const sanitizedEmail = email.toLowerCase()

    try {
      const services = await this.getServicesAndSetApiClientValues(dataRegion)

      if (!selectedAddiction) {
        throw Error(SignupMachineError.NoAddictionSelected)
      }

      if (email && password) {
        // EmailPassword
        await this.createUserCognitoIdentity(
          { email: sanitizedEmail, password, firstName, lastName },
          {
            registrationPoolId: services.registrationPoolId,
            registrationClientId: services.registrationClientId,
          }
        )
        const sessionData = await this.fetchCognitoUserSession(
          { email: sanitizedEmail, password },
          {
            registrationPoolId: services.registrationPoolId,
            registrationClientId: services.registrationClientId,
          }
        )

        const jwtToken = sessionData.getAccessToken().getJwtToken()
        try {
          await this.postUserDetails(
            selectedAddiction,
            quittingPriority,
            multipleLanguages,
            user.language,
            jwtToken
          )

          return Promise.resolve()
        } catch (error) {
          return Promise.reject({
            message: SignupMachineError.InternalError,
          })
        }
      } else if (email) {
        // EmailOTP
        await this.createUserCognitoIdentity(
          { email: sanitizedEmail, firstName, lastName },
          {
            registrationPoolId: services.registrationPoolId,
            registrationClientId: services.registrationClientId,
            registrationEndpoint: services.registrationEndpointUrl,
          }
        )
        await this.fetchCognitoUserSession(
          { email: sanitizedEmail },
          {
            registrationPoolId: services.registrationPoolId,
            registrationClientId: services.registrationClientId,
            registrationEndpoint: services.registrationEndpointUrl,
          }
        ) // should trigger OTP challenge
      } else {
        // PhoneOTP
        await this.createUserCognitoIdentity(
          { cellPhoneCountryCode, cellPhoneNumber, firstName, lastName },
          {
            registrationPoolId: services.registrationPoolId,
            registrationClientId: services.registrationClientId,
            registrationEndpoint: services.registrationEndpointUrl,
          }
        )
        await this.fetchCognitoUserSession(
          { cellPhoneCountryCode, cellPhoneNumber },
          {
            registrationPoolId: services.registrationPoolId,
            registrationClientId: services.registrationClientId,
            registrationEndpoint: services.registrationEndpointUrl,
          }
        ) // should trigger OTP challenge
      }
    } catch (error) {
      return Promise.reject(error)
    }
  }

  /**
   * resendOTPCode
   */
  public static resendOTPCode = async (context: SignupContext) => {
    const {
      user: { cellPhoneCountryCode, cellPhoneNumber, email },
    } = context
    const sanitizedEmail = email.toLowerCase()

    try {
      if (email) {
        // EmailOTP
        await this.fetchCognitoUserSession(
          { email: sanitizedEmail },
          {
            registrationPoolId: this.cognitoUserPoolId,
            registrationClientId: this.cognitoClientId,
            registrationEndpoint: this.cognitoEndpoint,
          }
        )
      } else {
        // PhoneOTP
        await this.fetchCognitoUserSession(
          { cellPhoneCountryCode, cellPhoneNumber },
          {
            registrationPoolId: this.cognitoUserPoolId,
            registrationClientId: this.cognitoClientId,
            registrationEndpoint: this.cognitoEndpoint,
          }
        )
      }
    } catch (error) {
      return Promise.reject(error)
    }
  }

  /**
   * submitOTPCode
   *
   * @returns {Promise<void>}
   */
  public static submitOTPCode = async (
    context: SignupContext,
    event: SubmitOTPCode
  ) => {
    const {
      user,
      selectedAddiction,
      quittingPriority,
      company: { multipleLanguages },
    } = context

    if (!selectedAddiction) {
      throw Error(SignupMachineError.NoAddictionSelected)
    }

    const cognitoUser = context.errors.otp
      ?.cognitoUser as AmazonCognitoIdentity.CognitoUser

    const sessionData =
      await new Promise<AmazonCognitoIdentity.CognitoUserSession>(
        (resolve, reject) =>
          cognitoUser.sendCustomChallengeAnswer(
            event.value.otpCode.toString(),
            {
              onSuccess: (sessionData) => {
                trackApiRequestSuccess({ name: 'OTP Code challenge success' })
                resolve(sessionData)
              },
              customChallenge: (customChallenge: {
                CustomChallengeSubType: string
                DeliveryMedium: OtpCodeDeliveryMedium
                Destination: string
              }) => {
                trackApiRequestSuccess({
                  name: 'New OTP Code challenge needed because of wrong code',
                })
                reject({
                  otp: {
                    deliveryMedium: customChallenge.DeliveryMedium,
                    destination: customChallenge.Destination,
                    cognitoUser,
                    newCodeRequired: true,
                  },
                })
              },
              onFailure: (error) => {
                trackApiRequestError({
                  error: {
                    message: error.message,
                    status: error.status,
                  },
                  name: 'Error in verifying OTP Code',
                })

                return reject({
                  message: SignupMachineError.InternalError,
                })
              },
            },
            { signInOtpFlowVersion: 'V2' }
          )
      )

    const jwtToken = sessionData.getAccessToken().getJwtToken()
    try {
      await this.postUserDetails(
        selectedAddiction,
        quittingPriority,
        multipleLanguages,
        user.language,
        jwtToken
      )

      return Promise.resolve()
    } catch (error) {
      return Promise.reject({
        message: SignupMachineError.InternalError,
      })
    }
  }

  /**
   * lighterB2BSave
   *
   * Function that fires off requests to B2B save and scheduler
   *
   * @returns {Promise}
   */
  public static lighterB2BSave = async (context: SignupContext) => {
    const {
      user,
      company: { companyId, dataRegion },
    } = context

    try {
      const services = await this.getServicesAndSetApiClientValues(dataRegion)
      const sessionData: AmazonCognitoIdentity.CognitoUserSession =
        await this.sessionRefresh(services)

      if (!sessionData) {
        throw Error(SignupMachineError.NoSession)
      }

      const jwtToken = sessionData.getAccessToken().getJwtToken()
      try {
        await this.putB2BDetails(user, companyId, jwtToken)
      } catch (error) {
        return Promise.reject(error)
      }
      try {
        const starterCallData = await this.fetchStarterCallUrl(sessionData)
        return Promise.resolve(starterCallData)
      } catch (error) {
        return Promise.reject({
          message: SignupMachineError.InternalError,
        })
      }
    } catch (error) {
      return Promise.reject(error)
    }
  }

  public static updateUserDetails = async ({
    selectedAddiction,
    quittingPriority,
    user: { language },
    company: { dataRegion, multipleLanguages },
  }: SignupContext) => {
    if (!selectedAddiction) {
      return Promise.reject(SignupMachineError.NoAddictionSelected)
    }

    const services = await this.getServicesAndSetApiClientValues(dataRegion)
    const sessionData: AmazonCognitoIdentity.CognitoUserSession =
      await this.sessionRefresh(services)

    if (!sessionData) {
      Promise.reject(SignupMachineError.NoSession)
    }

    const jwtToken = sessionData.getAccessToken().getJwtToken()
    return this.postUserDetails(
      selectedAddiction,
      quittingPriority,
      multipleLanguages,
      language,
      jwtToken
    )
  }

  public static cognitoUpdatePhoneNumber = async ({
    user: { cellPhoneNumber, cellPhoneCountryCode, email, password },
    company: { dataRegion },
  }: SignupContext) => {
    const services = await this.getServicesAndSetApiClientValues(dataRegion)
    return new Promise((resolve, reject) => {
      if (!services) {
        reject(SignupMachineError.InternalError)
      }
      const userPool = new AmazonCognitoIdentity.CognitoUserPool({
        UserPoolId: services.registrationPoolId,
        ClientId: services.registrationClientId,
        Storage: window.sessionStorage,
      })

      const cognitoUser = userPool.getCurrentUser()
      if (!cognitoUser) {
        return reject(SignupMachineError.NoUser)
      }

      const authenticationDetails =
        new AmazonCognitoIdentity.AuthenticationDetails({
          Username: email.toLowerCase(),
          Password: password,
        })

      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: () => {
          cognitoUser?.updateAttributes(
            [
              new AmazonCognitoIdentity.CognitoUserAttribute({
                Name: 'phone_number',
                Value: formatPhoneNumber(cellPhoneCountryCode, cellPhoneNumber),
              }),
            ],
            (error, result) => {
              if (error) {
                reject(error)
              } else {
                resolve(result)
              }
            }
          )
        },
        onFailure: (error) => {
          return reject(error)
        },
      })
    })
  }

  /**
   * saveMotivations
   */
  public static saveMotivations = async (context: SignupContext) => {
    if (context.motivations) {
      const services = await this.getServicesAndSetApiClientValues(
        context.company.dataRegion
      )
      const sessionData: AmazonCognitoIdentity.CognitoUserSession =
        await this.sessionRefresh(services)

      if (!sessionData) {
        Promise.reject(SignupMachineError.NoSession)
      }

      const jwtToken = sessionData.getAccessToken().getJwtToken()

      return apiClient.post<{
        success: boolean
        insertedRecordsLength: number
      }>(
        'user-defined-fields/me',
        { motivations: context.motivations },
        { headers: { Authorization: jwtToken } }
      )
    }
  }

  public static saveB2BDetails = async (context: SignupContext) => {
    // jwtToken has to be saved to cache before using this method
    const jwtToken =
      this.cognitoClientId &&
      this.cognitoUserPoolId &&
      (await this.getJwtTokenIfSessionExists())

    if (!jwtToken) {
      console.error('Missing jwtToken in saveB2BDetails!')
      return Promise.reject()
    }

    return this.putB2BDetails(context.user, context.company.companyId, jwtToken)
  }

  /**
   * Saves signupContext to backend (needed for rejoining abandoned flow - lighter flow)
   * conditions:
   *   - context.flowVersion === FLowVersion.LighterV1
   *   - context.shouldSaveUserProgress == true
   *   - cognitoClientId && cognitoUserPoolId set in AuthServices
   *   - there's current cognito session for userPool created using cognitoClientId && cognitoUserPoolId
   * @param context
   * @returns
   */
  public static updateUserProgressIfNeeded = async (
    state: StateType
  ): Promise<unknown> => {
    if (
      import.meta.env.VITE_ENABLE_REJOIN_REG_FLOW !== 'true' || // feature flag has to be ON
      state.hasTag('loading') || // should not save for 'loading' views
      !LighterBasedFlowVersions.includes(state.context.flowVersion) || // should save only for Lighter-based flows with 'shouldSaveUserProgress' flag
      !state.context.shouldSaveUserProgress
    ) {
      return Promise.resolve()
    }

    // prepares data earlier to avoid race condition
    const data = {
      appVersion: pkg.version,
      date: formatISO(new Date()),
      context: JSON.stringify(getSanitizedContext(state.context)),
    }

    // should save only if there's live cognito session
    const jwtToken =
      this.cognitoClientId &&
      this.cognitoUserPoolId &&
      (await this.getJwtTokenIfSessionExists())
    if (!jwtToken) {
      return Promise.resolve()
    }

    return apiClient.put<{ success: boolean }>(
      B2B_ONBOARDING_PROGRESS_URL,
      data,
      {
        headers: { Authorization: jwtToken },
      }
    )
  }

  /**
   * Saves ClientId and PoolId (needed for retrieving userPool and current cognito session) to AuthServices
   * They are region specific
   * They are cleared after page refresh
   * @param services
   */
  private static cacheCognitoConsts(services: Services): void {
    this.cognitoClientId = services.registrationClientId
    this.cognitoUserPoolId = services.registrationPoolId
    this.cognitoEndpoint = services.registrationEndpointUrl
  }

  /**
   * Returns JwtToken if member is logged in
   * @returns Promise<string | undefined>
   */
  private static async getJwtTokenIfSessionExists(): Promise<
    string | undefined
  > {
    let sessionData: AmazonCognitoIdentity.CognitoUserSession

    try {
      sessionData = await this.sessionRefresh()
    } catch (err) {
      return Promise.resolve(undefined)
    }

    return Promise.resolve(sessionData.getAccessToken().getJwtToken())
  }

  /**
   *
   * Fetches a user's onboarding progress using {@link fetchB2BOnboardingProgress}
   * (currently in the form of stored signup machine context). The context is
   * returned parsed. An error will be thrown if B2B details (fetched using
   * {@link fetchB2BDetails}) have already been saved.
   *
   * Could be adapted to take a companyId if the company ID could be passed in
   * the url.
   *
   * TODO: Confirm error vs success with product.
   *
   * @returns Promise<{context: {@link ProgressSignupContext}}>
   */
  public static getProgress = async (): Promise<{
    context: ProgressSignupContext
  }> => {
    return new Promise((resolve, reject) => {
      const handleAndRejectError = (
        error: SignupMachineError,
        context?: ProgressSignupContext
      ) => {
        logMessage('getProgress.' + error)
        reject({
          message: error,
          ...(!!context && { context }),
        })
      }

      this.fetchB2BOnboardingProgress()
        .then((progressResponse) => {
          const progress = progressResponse?.data?.userProgress?.Items?.[0]
          if (
            progressResponse?.data?.success &&
            progressResponse?.data?.userProgress &&
            progress
          ) {
            let parsedContext: unknown
            try {
              parsedContext = getSanitizedContext(JSON.parse(progress.context))
            } catch (error) {
              return handleAndRejectError(
                SignupMachineError.ProgressGetFailedParse
              )
            }

            progressSignupContextSchema
              .validate(parsedContext)
              .then((isValid) => {
                if (isValid) {
                  fetchB2BDetailsHandler(parsedContext as ProgressSignupContext)
                } else {
                  handleAndRejectError(
                    SignupMachineError.ProgressContextSchemaValidationError
                  )
                }
              })
              .catch(() => {
                handleAndRejectError(
                  SignupMachineError.ProgressContextSchemaValidationError
                )
              })
          } else {
            // The flow could be changed to take the user to the company
            // selector to enable us to check b2b progress.
            return handleAndRejectError(
              SignupMachineError.ProgressGetNoProgress
            )
          }
        })
        .catch(() => {
          return handleAndRejectError(SignupMachineError.ProgressGetFail)
        })

      const fetchB2BDetailsHandler = (parsedContext: ProgressSignupContext) =>
        this.fetchB2BDetails(parsedContext.company.companyId)
          .then((b2bResponse) => {
            return handleAndRejectError(
              b2bResponse?.data?.success
                ? SignupMachineError.ProgressGetB2BAlreadySaved
                : SignupMachineError.ProgressGetB2BFail,
              parsedContext
            )
          })
          .catch((error: AxiosError | Error) => {
            // If the B2B details fetch fails with a 404 then this is an
            // expected error meaning that the B2B details save hasn't
            // successfully occurred yet and we should enable the member to
            // rejoin the flow and complete the B2B save.
            if ((error as AxiosError)?.response?.status === 404) {
              logMessage('getProgress.b2b404')
              return resolve({ context: parsedContext })
            } else {
              return handleAndRejectError(
                SignupMachineError.ProgressGetB2BFail,
                parsedContext
              )
            }
          })
    })
  }

  /**
   * Fetches a user's onboarding progress, currently includes
   * {@link B2BOnboardingProgress}.
   *
   * @returns Promise<{@link AxiosResponse}<{ success: boolean } & {@link B2BOnboardingProgress}>
   */
  private static fetchB2BOnboardingProgress = async (): Promise<
    AxiosResponse<{ success: boolean } & B2BOnboardingProgress>
  > => {
    const jwtToken =
      this.cognitoClientId &&
      this.cognitoUserPoolId &&
      (await this.getJwtTokenIfSessionExists())

    if (!jwtToken) {
      const error = SignupMachineError.NoSession
      logMessage('fetchB2BDetails.' + error)
      return Promise.reject({ message: error })
    }

    const url = B2B_ONBOARDING_PROGRESS_URL
    trackApiRequestStart({
      endpointUrl: url,
      method: 'get',
      name: 'Fetch b2b onboarding progress',
    })
    // Converting to observable for ease of use of RxJS' tap operator for
    // tracking and then back to a Promise.
    return firstValueFrom(
      from(
        apiClient.get<
          {
            success: boolean
          } & B2BOnboardingProgress
        >(url, {
          headers: { Authorization: jwtToken },
        })
      ).pipe(
        tap({
          next: () => {
            trackApiRequestSuccess({
              endpointUrl: url,
              method: 'get',
              name: 'Fetched b2b onboarding progres',
            })
          },
          error: (error) => {
            trackApiRequestError({
              endpointUrl: url,
              method: 'get',
              name: 'Error fetching b2b onboarding progress',
              error,
            })
          },
        })
      )
    )
  }

  /**
   * Fetches a user's B2B details, currently includes
   * {@link B2BDetailsUserResponse}.
   *
   * @param companyId
   * @returns Promise<{@link AxiosResponse}<{ success: boolean } & {@link B2BDetailsUserResponse}>
   */
  private static fetchB2BDetails = async (
    companyId: number
  ): Promise<AxiosResponse<{ success: boolean } & B2BDetailsUserResponse>> => {
    const jwtToken =
      this.cognitoClientId &&
      this.cognitoUserPoolId &&
      (await this.getJwtTokenIfSessionExists())
    if (!jwtToken) {
      const error = SignupMachineError.NoSession
      logMessage('fetchB2BDetails.' + error)
      return Promise.reject({ message: error })
    }

    const url = this.getB2BDetailsUrl(companyId)
    trackApiRequestStart({
      endpointUrl: url,
      method: 'get',
      name: 'Fetch b2b details',
    })
    // Converting to observable for ease of use of RxJS' tap operator for
    // tracking and then back to a Promise.
    return firstValueFrom(
      from(
        apiClient.get<{ success: boolean } & B2BDetailsUserResponse>(url, {
          headers: { Authorization: jwtToken },
        })
      ).pipe(
        tap({
          next: () => {
            trackApiRequestSuccess({
              endpointUrl: url,
              method: 'get',
              name: 'Fetched b2b details',
            })
          },
          error: (error) => {
            trackApiRequestError({
              endpointUrl: url,
              method: 'get',
              name: 'Error fetching b2b details',
              error,
            })
          },
        })
      )
    )
  }
}
