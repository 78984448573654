/** OneTrust Cookies Consent Notice data domain script id for signup.pelagohealth.com */
const OneTrustCookieConsentDataDomainScriptProd =
  '018f1076-8a4e-7828-8b43-0a4b2a1e95b0'
/** OneTrust Cookies Consent Notice data domain script id for signup-dev.pelagohealth.com */
const OneTrustCookieConsentDataDomainScriptDev =
  '018f1080-5dd7-793f-ac18-d59339752c04'

/**
 * Programmatically adds the One Trust Cookie Banner script to the DOM.
 *
 * A callback function `OptanonWrapper` can also be added for use:
 * {@link https://my.onetrust.com/s/article/UUID-29158b4e-22f6-0067-aa36-94f3b8cf3561?language=en_US}
 */
export function addOneTrustCookieBanner() {
  const oneTrustScriptElement = document.createElement('script')
  oneTrustScriptElement.setAttribute(
    'src',
    'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js'
  )
  oneTrustScriptElement.setAttribute('type', 'text/javascript')
  oneTrustScriptElement.setAttribute('charset', 'UTF-8')
  oneTrustScriptElement.setAttribute(
    'data-domain-script',
    import.meta.env.PROD
      ? OneTrustCookieConsentDataDomainScriptProd
      : OneTrustCookieConsentDataDomainScriptDev
  )
  document.head.appendChild(oneTrustScriptElement)
}
